// Create a context for auth and current user
import React, { createContext, useContext, useEffect, useState } from "react";
import { firestore, auth } from "../firebase";
import { EmailAuthProvider, User, signInWithCustomToken } from "firebase/auth";
import { onAuthStateChanged, GoogleAuthProvider } from "firebase/auth";

import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { set } from "firebase/database";
const gauthProvider = new GoogleAuthProvider();
const baseUrl =
  process.env.REACT_APP_BASE_URL ||
  "http://127.0.0.1:5001/resu-me-a5cff/us-central1/api";

interface AuthContext {
  loading: boolean;
  error: string | null;
  data: any;
  user: User | null;
  isAuthenticated: boolean;
  isProfileComplete: boolean;
  isRepoCompleted: boolean;
  isEmailVerified: boolean;
  authToken: string | null;
  logout: () => void;
  loginWithToken: (customToken: string) => Promise<any>;
}
const context = createContext<AuthContext>({
  loading: true,
  error: null,
  data: null,
  user: null,
  isAuthenticated: false,
  isProfileComplete: false,
  isRepoCompleted: false,
  isEmailVerified: false,
  authToken: null,
  logout: () => {},
  loginWithToken: async (customToken: string) => {},
});

const ProviderAuth = ({ children }: { children: React.ReactNode }) => {
  const auth = useProvideAuth();
  return <context.Provider value={auth}>{children}</context.Provider>;
};

const useAuth = () => {
  return useContext(context);
};

const useProvideAuth = () => {

  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null,
    user: null as User | null,
    isAuthenticated: false,
    isProfileComplete: false,
    isRepoCompleted: false,
    isEmailVerified: false,
    authToken: null,
  });

  useEffect(() => {
   
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      //
      if (user) {
        setState((prev) => ({
          ...prev,
          loading: false,
          isAuthenticated: true,
          user: user,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          loading: false,
          isAuthenticated: false,
          user: null,
        }));
      }
    });
    
    return () => unsubscribe();
  }, []);

  const loginWithToken = async (customToken: string) => {
    try {
      let userCred = await signInWithCustomToken(auth, customToken);
      let user = userCred.user;
      return {
        error: false,
        message: "User logged in successfully",
      };
    } catch (err: any) {
      return {
        error: true,
        message: err.message,
        code: err.code,
      };
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
    } catch (err) {
      console.log(err);
    }
  };

  // return
  return {
    ...state,
    logout,
    loginWithToken,
  };
};

export { ProviderAuth, useAuth };
