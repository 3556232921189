import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import "firebase/compat/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-DZbo61tN9YonKCtt_8UKg8YhejWsLNA",
  authDomain: "train-165d3.firebaseapp.com",
  databaseURL: "https://train-165d3.firebaseio.com",
  projectId: "train-165d3",
  storageBucket: "train-165d3.appspot.com",
  messagingSenderId: "26978496766",
  appId: "1:26978496766:web:1d370bbecd8da0640f35af",
  measurementId: "G-KY04HC84C7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-restricted-globals
declare global {
    interface Window {
        FIREBASE_APPCHECK_DEBUG_TOKEN: boolean;
    }
}

console.log(window.location.hostname);
if (window.location.hostname === 'localhost') {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LeMZHEpAAAAAFlUmB_mjeyfTmW6sXbAt_78sK-B'),
    isTokenAutoRefreshEnabled: true
    
})
// const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const database = getDatabase(app);
const auth = getAuth(app);

export { firestore, database, auth };