export const EXERCISE_DETAILS = {
  singleArmRopeTricepExtension: {
    key: "singleArmRopeTricepExtension",
    name: "Single Arm Tricep Pushdown / Extension",
    altNames: "",
    plurName: "Single Arm Tricep Pushdown / Extension",
    shortName: "1-Arm Cable Tri Push Down",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableTricepExtensionSingleArmRope",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 60,
    hash: "ae3cbd76e5",
  },
  singleArmCableTricepKickback: {
    key: "singleArmCableTricepKickback",
    name: "Single Arm Cable Tricep Kickback",
    altNames: "",
    plurName: "Single Arm Cable Tricep Kickback",
    shortName: "1-Arm Cbl Tri Kickback",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 15,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableTricepKickback",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 2,
    pop: 50,
    hash: "7c87c73d5d",
  },
  singleArmOverheadTricepExtHigh: {
    key: "singleArmOverheadTricepExtHigh",
    name: "Single Arm Overhead Tricep Extension High",
    altNames: "",
    plurName: "Single Arm Overhead Tricep Extension High",
    shortName: "1-Arm Tri Ext. High",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableTricepExtensionOverheadSingleArmHigh",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 50,
    hash: "33d9a9dd96",
  },
  singleArmOverheadTricepExtLow: {
    key: "singleArmOverheadTricepExtLow",
    name: "Single Arm Overhead Tricep Extension Low",
    altNames: "",
    plurName: "Single Arm Overhead Tricep Extension Low",
    shortName: "1-Arm Tri Ext. Low",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableTricepExtensionOverheadSingleArmLow",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 40,
    hash: "19dce6dabf",
  },
  barbellDoubleArmLandminePress: {
    key: "barbellDoubleArmLandminePress",
    name: "Double Arm Landmine Press",
    altNames: "",
    plurName: "Double Arm Landmine Press",
    shortName: "2 Hand Landmine Press ",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "The landmine press is a great alternative to a shoulder press !",
    calibKey: "barbellLandminePressDoubleArm",
    "AI Alternative Exercises": "landmineSquatPressDoubleArm",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "10766c5cc4",
  },
  abCoaster: {
    key: "abCoaster",
    name: "Ab Coaster",
    altNames: "Abdominal",
    plurName: "Ab Coaster",
    shortName: "Ab Coaster",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "Ab rollouts are a very effective core exercise using an ab roller. On your knees, firmly hold each side of the roller and extend your arms outwards moving your chest closer to the ground. Once fully extended, engage your core and bring your arms closer to your knees.",
    calibKey: "machineAbCoaster",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "0883ad9084",
  },
  abCrunchMachine: {
    key: "abCrunchMachine",
    name: "Ab Crunch Machine",
    altNames: "",
    plurName: "Ab Crunch Machine",
    shortName: "Ab Crunch Machine",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineAbCrunch",
    "AI Alternative Exercises": "sitUp,backExtension,crunch,cableCrunch",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 60,
    hash: "910ee6eaa7",
  },
  abRollOut: {
    key: "abRollOut",
    name: "Ab Rollout",
    altNames: "",
    plurName: "Ab Rollouts",
    shortName: "Ab Rollout",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Ab rollouts are a very effective core exercise using an ab roller. On your knees, firmly hold each side of the roller and extend your arms outwards moving your chest closer to the ground. Once fully extended, engage your core and bring your arms closer to your knees.",
    calibKey: "bodyweightAbRollout",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "770be3830f",
  },
  dumbbellArnoldPress: {
    key: "dumbbellArnoldPress",
    name: "Arnold Press",
    altNames: "",
    plurName: "Arnold Press",
    shortName: "Arnold Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "The Arnold press is a very effective exercise in strengthening the upper body, primarily targeting deltoids and biceps. To perform this exercise you must use dumbells or kettlebells of varying weights while either sitting or standing.",
    calibKey: "dumbbellArnoldPress",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 50,
    hash: "debeae180d",
  },
  assaultBike: {
    key: "assaultBike",
    name: "Assault Bike",
    altNames: "",
    plurName: "Assault Bike",
    shortName: "Assault Bike",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "distance",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "assaultBike",
    "AI Alternative Exercises": "",
    calCoef: 0.1,
    TVLFactor: 1,
    pop: 60,
    hash: "57671a46ac",
  },
  assistedChinUps: {
    key: "assistedChinUps",
    name: "Assisted Chin Up",
    altNames: "Negative,Graviton",
    plurName: "Assisted Chin Up",
    shortName: "Assisted Chin Ups",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineAssistedChinUps",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "a9526330f7",
  },
  assistedDips: {
    key: "assistedDips",
    name: "Assisted Dip",
    altNames: "Negative,Graviton",
    plurName: "Assisted Dip",
    shortName: "Assisted Dips",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineAssistedDips",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "144204d1b0",
  },
  assistedPullUps: {
    key: "assistedPullUps",
    name: "Assisted Pull Up",
    altNames: "Negative,Graviton",
    plurName: "Assisted Pull Up",
    shortName: "Assisted Pull Ups",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineAssistedPullUps",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "a2e05e1faa",
  },
  backExtension: {
    key: "backExtension",
    name: "Back Extension (HyperExtension)",
    altNames: "",
    plurName: "Back Extensions",
    shortName: "Back (Hyper) Ext.",
    primMusc1: "lowerBack",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "While lying on a inclined bench or extension machine, slowly lower your body towards the floor, bending at the hips. Ensure your back is kept straight throughout the exercise.",
    calibKey: "backExtensionTraditional",
    "AI Alternative Exercises": "cableCrunch,crunch",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "b08ef3ca61",
  },
  barbellBackSquat: {
    key: "barbellBackSquat",
    name: "Back Squat",
    altNames: "",
    plurName: "Back Squat",
    shortName: "Back Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Back squats are a very effective lower body exercise that strengthens your glutes, hamstrings, and quadriceps. It can be completed by setting a bar on the back of your body by the upper trapezius muscle near the base of  the neck.",
    calibKey: "barbellBackSquat",
    "AI Alternative Exercises":
      "barbellBulgarianSplit,barbellFrontSquat,barbellLunge,bodyweightSquat",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 90,
    hash: "081135bec4",
  },
  ballSlam: {
    key: "ballSlam",
    name: "Ball Slams",
    altNames: "",
    plurName: "Ball Slams",
    shortName: "Ball Slams",
    primMusc1: "shoulders",
    primMusc2: "abs",
    secMusc1: "lats",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "ballSlam",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "d8a64b1c79",
  },
  resistanceBandBicepCurl: {
    key: "resistanceBandBicepCurl",
    name: "Resistance Band Bicep Curl",
    altNames: "",
    plurName: "Resistance Band Bicep Curls",
    shortName: "Band Bicep Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandBicepCurlTraditional",
    "AI Alternative Exercises": "cableRopeBicepCurl,cableBicepCurl",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 20,
    hash: "beeeb44be3",
  },
  resistanceBandLateralWalk: {
    key: "resistanceBandLateralWalk",
    name: "Resistance Band Lateral Walk",
    altNames: "",
    plurName: "Resistance Band Lateral Walks",
    shortName: "Band Lateral Walk",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandLateralWalk",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 30,
    hash: "4ed26ba832",
  },
  resistanceBandPullApart: {
    key: "resistanceBandPullApart",
    name: "Band Pull Apart",
    altNames: "Splitter",
    plurName: "Band Pull Apart",
    shortName: "Band Pull Apart",
    primMusc1: "shoulders",
    primMusc2: "triceps",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandPullApart",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "65d17a29eb",
  },
  resistanceBandSquat: {
    key: "resistanceBandSquat",
    name: "Resistance Band Squat",
    altNames: "",
    plurName: "Resistance Band Squat",
    shortName: "Band Squat Press",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandSquatTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "e2d9a58723",
  },
  resistanceBandBenchPress: {
    key: "resistanceBandBenchPress",
    name: "Resistance Band Bench Press",
    altNames: "",
    plurName: "Resistance Band Bench Press",
    shortName: "Banded Bench Press",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "resistanceBandBenchPressTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "64e6c1666b",
  },
  resistanceBandBentOverRow: {
    key: "resistanceBandBentOverRow",
    name: "Resistance Band Bent Over Row",
    altNames: "",
    plurName: "Resistance Band Bent Over Row",
    shortName: "Banded Bent Over Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandBentOverRow",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "2e2ca08eae",
  },
  resistanceBandCalfRaise: {
    key: "resistanceBandCalfRaise",
    name: "Resistance Band Calf Raise ",
    altNames: "Extension",
    plurName: "Resistance Band Calf Raise ",
    shortName: "Banded Calf Raise",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandCalfRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "e0c42e5505",
  },
  resistanceBandDeadlift: {
    key: "resistanceBandDeadlift",
    name: "Resistance Band Deadlift",
    altNames: "",
    plurName: "Resistance Band Deadlift",
    shortName: "Banded Deadlift",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandDeadliftTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "8cef438680",
  },
  resistanceBandSingleLegDeadlift: {
    key: "resistanceBandSingleLegDeadlift",
    name: "Resistance Band Deadlift (Singe Leg) ",
    altNames: "",
    plurName: "Resistance Band Deadlift (Singe Leg) ",
    shortName: "Banded Deadlift (1-Leg)",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandDeadliftSingleLeg",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "fe5f4b8b95",
  },
  resistanceBandFrontSquat: {
    key: "resistanceBandFrontSquat",
    name: "Resistance Band Front Squat ",
    altNames: "",
    plurName: "Resistance Band Front Squat ",
    shortName: "Banded Front Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandFrontSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "497d779c25",
  },
  resistanceBandLatRaise: {
    key: "resistanceBandLatRaise",
    name: "Resistance Band Lat Raise",
    altNames: "",
    plurName: "Resistance Band Lat Raise",
    shortName: "Banded Lat Raise",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "lats",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandLatRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "3fa415130d",
  },
  resistanceBandSingleLegRomanianDeadlift: {
    key: "resistanceBandSingleLegRomanianDeadlift",
    name: "Resistance Band Romanian Deadlift (Single Leg) ",
    altNames: "",
    plurName: "Resistance Band Romanian Deadlift (Single Leg) ",
    shortName: "Banded RDL (1-Leg)",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandRomanianDeadliftSingleLeg",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "9f3bc2133a",
  },
  resistanceBandShoulderPress: {
    key: "resistanceBandShoulderPress",
    name: "Resistance Band Shoulder Press",
    altNames: "",
    plurName: "Resistance Band Shoulder Press",
    shortName: "Banded Shoulder Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandShoulderPress",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "9fa66104c6",
  },
  resistanceBandTricepExtension: {
    key: "resistanceBandTricepExtension",
    name: "Resistance Band Tricep Pushdown ",
    altNames: "",
    plurName: "Resistance Band Tricep Pushdown ",
    shortName: "Banded Tri Push Down",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandTricepExtension",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "4122b66326",
  },
  barbellBenchPress: {
    key: "barbellBenchPress",
    name: "Barbell Bench Press",
    altNames: "",
    plurName: "Barbell Bench Press",
    shortName: "Barbell Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "bench",
    tip: "",
    desc: "The bench press is an essential upper body exercise that can be completed with a barbell or dumbbells. It focuses on your chest and triceps. The exercise allows you to build muscle and strength. ",
    calibKey: "barbellBenchPressTraditional",
    "AI Alternative Exercises":
      "barbellInclineBenchPress,barbellCloseGripBenchPress,barbellMilitaryPress,seatedOverheadPressMachineRegularGrip",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 80,
    hash: "62100947ff",
  },
  barbellBicepCurl: {
    key: "barbellBicepCurl",
    name: "Barbell Bicep Curl",
    altNames: "",
    plurName: "Barbell Bicep Curls",
    shortName: "Barbell Curls",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Bicep curls are an isolated movement that can be completed with a barbell, dumbbells, cables, or resistance bands. They focus on the front of your arm. ",
    calibKey: "barbellBicepCurlTraditional",
    "AI Alternative Exercises":
      "ezBarBicepCurl,cableBicepCurl,dumbbellConcentrationCurl,ezBarPreacherCurl",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 80,
    hash: "d79a866e13",
  },
  barbellDeadlift: {
    key: "barbellDeadlift",
    name: "Barbell Deadlift",
    altNames: "",
    plurName: "Barbell Deadlift",
    shortName: "Barbell Deadlift",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "quads",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Deadlifts are an essential exercise that help build both strength, and muscle. They can be completed with dumbbells, a barbell, a trap bar or cables. The exercise focuses on your whole body, with an emphasis on your lower back.  ",
    calibKey: "barbellDeadliftTraditional",
    "AI Alternative Exercises":
      "barbellRackPull,dumbbellDeadlift,trapBarDeadlift,barbellShrug,dumbbellBulgarianSplitSquat",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 80,
    hash: "44bd7098a7",
  },
  barbellForearmCurl: {
    key: "barbellForearmCurl",
    name: "Barbell Forearm Curl",
    altNames: "Wrist",
    plurName: "Barbell Forearm Curls",
    shortName: "Barbell Forearm Curl",
    primMusc1: "forearms",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellForearmCurl",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 30,
    hash: "300137ace0",
  },
  barbellLunge: {
    key: "barbellLunge",
    name: "Barbell Lunge",
    altNames: "",
    plurName: "Barbell Lunge",
    shortName: "Barbell Lunge",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "quads",
    secMusc2: "calves",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Lunges are an essential lower body exercise that helps build stability, strength, and muscle. They can be completed with dumbbells, a barbell, cables, or without any equipment. They focuses on your lower body, with an emphasis on the front of your legs.",
    calibKey: "barbellLungeTraditional",
    "AI Alternative Exercises": "barbellBulgarianSplit,barbellBackSquat",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "b3f41a7c8a",
  },
  barbellBentoverRow: {
    key: "barbellBentoverRow",
    name: "Bent Over Barbell Row",
    altNames: "",
    plurName: "Bent Over Barbell Row",
    shortName: "Barbell Row",
    primMusc1: "lats",
    primMusc2: "biceps",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Bent over rows are an essential upper body exercise that focus on your lats and biceps. They can be completed with a barbell, dumbbells, cables, or resistance bands. The exercise allows you to build both muscle and strength. ",
    calibKey: "barbellBentoverRowTraditional",
    "AI Alternative Exercises":
      "trapBarDeadlift,dumbbellRow,barbellRackPull,dumbbellOverheadPress,barbellDeadlift",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "8dfc7b9034",
  },
  barbellShrug: {
    key: "barbellShrug",
    name: "Barbell Shrug",
    altNames: "",
    plurName: "Barbell Shrug",
    shortName: "Barbell Shrug",
    primMusc1: "traps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "The Shoulder Shrug is a critical exercise in strengthening the shoulders and developing the upper trapezius muscle. It can be performed with a barbell, dumbbells, trap bar, resistance bands, parallel bar, or a smith machine. To effectively shoulder shrug you must stand tall with hands placed shoulder width apart, and slowly raise the shoulders as high as possible, before slowly lowering them back to starting position.",
    calibKey: "barbellShrug",
    "AI Alternative Exercises":
      "trapBarShrug,dumbbellShrug,barbellRackPull,sideBend,trapBarDeadlift",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "9a56889a85",
  },
  barbellStepUp: {
    key: "barbellStepUp",
    name: "Barbell Step Up",
    altNames: "",
    plurName: "Barbell Step ups",
    shortName: "Barbell Step Up",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "With one leg, step onto the center of a bench or box and straighten your leg at the top. Repeat movement with opposite leg",
    calibKey: "barbellStepUp",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 30,
    hash: "8373ea87fe",
  },
  tBarRow: {
    key: "tBarRow",
    name: "Barbell T-Bar Row",
    altNames: "",
    plurName: "Barbell T-Bar Rows",
    shortName: "Barbell T-Bar Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "tBarRowTraditional",
    "AI Alternative Exercises":
      "barbellReverseGripBentoverRow,barbellFrontSquat,barbellBentoverRow",
    calCoef: 0.9,
    TVLFactor: 1,
    pop: 60,
    hash: "6b330550b0",
  },
  barbellSumoSquat: {
    key: "barbellSumoSquat",
    name: "Sumo Squat",
    altNames: "",
    plurName: "Sumo Squats",
    shortName: "Barbell Sumo Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellSumoSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "fb28d6f042",
  },
  battleRopes: {
    key: "battleRopes",
    name: "Battle Ropes",
    altNames: "",
    plurName: "Battle Ropes",
    shortName: "Battle Ropes",
    primMusc1: "lats",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "Using a rope, tuck your elbows into your sides and alternate pumping your arms up and down, creating alternate waves in the rope.",
    calibKey: "battleRopes",
    "AI Alternative Exercises": "boxJump",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 30,
    hash: "e031be648d",
  },
  barbellStandingCalfRaise: {
    key: "barbellStandingCalfRaise",
    name: "Barbell Standing Calf Raise",
    altNames: "Extension",
    plurName: "Barbell Standing Calf Raise",
    shortName: "BB Calf Raise",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellCalfRaiseStanding",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "96b994d1e5",
  },
  barbellPreacherCurl: {
    key: "barbellPreacherCurl",
    name: "Barbell Preacher Curl",
    altNames: "",
    plurName: "Barbell Preacher Curl",
    shortName: "BB Preacher Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellPreacherCurl",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "05dabc647a",
  },
  barbellSkullcrusher: {
    key: "barbellSkullcrusher",
    name: "Barbell SkullCrusher",
    altNames: "",
    plurName: "Barbell SkullCrushers",
    shortName: "BB SkullCrusher",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellSkullcrusher",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "a2d920871f",
  },
  barbellUprightRow: {
    key: "barbellUprightRow",
    name: "Barbell Upright Row",
    altNames: "",
    plurName: "Barbell Upright Row",
    shortName: "BB Upright Row",
    primMusc1: "shoulders",
    primMusc2: "traps",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Upright rows are a great exercise that can be completed with a barbell, dumbbells, or cables. They focus on both your shoulders and your traps. ",
    calibKey: "barbellUprightRow",
    "AI Alternative Exercises":
      "dumbbellUprightRow,cableUprightRow,barbellBentoverRow,dumbbellRow",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "cd61fca69d",
  },
  barbellSquatPress: {
    key: "barbellSquatPress",
    name: "Barbell Squat Press",
    altNames: "",
    plurName: "Barbell Squat Presses",
    shortName: "Bbl Squat Press",
    primMusc1: "hamstrings",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellSquatPress",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "03b0bcdfe0",
  },
  benchDips: {
    key: "benchDips",
    name: "Bench Dips",
    altNames: "",
    plurName: "Bench Dips",
    shortName: "Bench Dips",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bench",
    equip2: "",
    tip: "",
    desc: "Dips are an essential upper body strength exercise that primarily focuses on triceps and anterior deltoids. This can be performed either with a bench/raised platform or a dip rack.",
    calibKey: "benchDips",
    "AI Alternative Exercises": "dips,pullUp,mountainClimber",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 50,
    hash: "5e8987ef59",
  },
  benchSupportedDumbbellRow: {
    key: "benchSupportedDumbbellRow",
    name: "Bench Supported Dumbbell Row",
    altNames: "Chest",
    plurName: "Bench Supported Dumbbell Row",
    shortName: "Bench Supported Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "dumbbellRowInclineBench",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "d1614ce5f3",
  },
  cableBentoverRearDeltFly: {
    key: "cableBentoverRearDeltFly",
    name: "Bent Over Cable Rear Delt Fly",
    altNames: "Reverse Fly",
    plurName: "Bent Over Cable Rear Delt Fly",
    shortName: "Bentover Cbl Rear Delt Fly",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 55,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Rear delt fly is an excellent exercise that increases deltoid muscle definition and strength. It can be performed on the cable machine either upright or bent over.",
    calibKey: "cableRearDeltFlyBentover",
    "AI Alternative Exercises":
      "cableSingleArmLateralRaise,cableCrossoverHigh,cableCrossoverLow,cableRopeOverheadTriExtensionLow,cableStraightArmPulldown",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "ffce0ae184",
  },
  dumbbellBicepCurl: {
    key: "dumbbellBicepCurl",
    name: "Bicep Curl",
    altNames: "",
    plurName: "Bicep Curls",
    shortName: "Bicep Curls",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Bicep curls are an isolated movement that allow you to build muscle and tone the front of your arms. They are completed with dumbbells. ",
    calibKey: "dumbbellBicepCurlTraditional",
    "AI Alternative Exercises":
      "ezBarBicepCurl,dumbbellConcentrationCurl,barbellBicepCurl,cableBicepCurl,resistanceBandBicepCurl",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 90,
    hash: "50a31b3239",
  },
  bicycleCrunch: {
    key: "bicycleCrunch",
    name: "Bicycle Crunch",
    altNames: "",
    plurName: "Bicycle Crunch",
    shortName: "Bicycle Crunch",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Bicycle crunches help build strength and tone your core. They focus on the sides of your core, also known as your obliques.",
    calibKey: "bodyweightCrunchBicycle",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "738e176188",
  },
  birdDog: {
    key: "birdDog",
    name: "Bird Dog",
    altNames: "",
    plurName: "Bird Dogs",
    shortName: "Bird Dog",
    primMusc1: "abs",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Bird dogs are a great core exercise that improve core strength, stability, and overall coordination. They focus on the sides of your core, also known as your obliques. On your hands and knees slowly extend one arm and the opposite leg until fully flexed.",
    calibKey: "bodyweightBirdDog",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "a86ff9443d",
  },
  bodyweightCalfRaise: {
    key: "bodyweightCalfRaise",
    name: "Bodyweight Calf Raise",
    altNames: "Extension",
    plurName: "Bodyweight Calf Raises",
    shortName: "Bodyweight Calf Raises",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightCalfRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 50,
    hash: "5b5d65b9f8",
  },
  bodyweightGluteBridge: {
    key: "bodyweightGluteBridge",
    name: "Bodyweight Glute Bridge",
    altNames: "Hip Thruster",
    plurName: "Bodyweight Glute Bridge",
    shortName: "Bodyweight Glute Bridge   ",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightGluteBridge",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 50,
    hash: "0b90543c13",
  },
  bodyweightSquat: {
    key: "bodyweightSquat",
    name: "Bodyweight Squat",
    altNames: "",
    plurName: "Bodyweight Squat",
    shortName: "Bodyweight Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightSquat",
    "AI Alternative Exercises":
      "gobletSquat,bodyweightSquatJumps,bodyweightLunge,boxJump",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 50,
    hash: "98acc4f910",
  },
  boxJump: {
    key: "boxJump",
    name: "Box Jump",
    altNames: "",
    plurName: "Box Jumps",
    shortName: "Box Jumps",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "quads",
    secMusc2: "calves",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "other",
    tip: "",
    desc: "The box jump is an essential workout to strengthen your lower body including glutes, quadriceps, calves, and hamstrings. To perform a box jump you must have a box or step of varying height.",
    calibKey: "boxJump",
    "AI Alternative Exercises": "bodyweightSquatJumps",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "80fc21e7ba",
  },
  barbellBulgarianSplit: {
    key: "barbellBulgarianSplit",
    name: "Barbell Bulgarian Split Squat",
    altNames: "",
    plurName: "Barbell Bulgarian Split Squat",
    shortName: "Bulg. Split Squat",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "quads",
    secMusc2: "calves",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Bulgarian split squats are an advanced lower body exercise that can be completed with a barbell, kettlebell, dumbbells, or resistance bands. They place an emphasis on the front of your legs. ",
    calibKey: "barbellBulgarianSplit",
    "AI Alternative Exercises": "barbellBackSquat,barbellLunge",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "0df4b5e92a",
  },
  burpee: {
    key: "burpee",
    name: "Burpee",
    altNames: "",
    plurName: "Burpees",
    shortName: "Burpee",
    primMusc1: "quads",
    primMusc2: "chest",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "A pushup followed by a leap in the air.",
    calibKey: "bodyweightBurpee",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "866cce6b2d",
  },
  bodyweightLateralLunge: {
    key: "bodyweightLateralLunge",
    name: "Bodyweight Lateral Lunge",
    altNames: "",
    plurName: "Bodyweight Lateral Lunges",
    shortName: "BW Lateral Lunge",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightLateralLunge",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "3106a0c597",
  },
  cableBicepCurl: {
    key: "cableBicepCurl",
    name: "Cable Bicep Curl (Bar)",
    altNames: "",
    plurName: "Cable Bicep Curl (Bar)",
    shortName: "Cable Bicep Curl (Bar)",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Bicep curls are an isolated movement that can be completed with a barbell, dumbbells, cables, or resistance bands. They focus on the front of your arm. ",
    calibKey: "cableBicepCurlBar",
    "AI Alternative Exercises":
      "ezBarBicepCurl,barbellBicepCurl,cableRopeBicepCurl,machinePreacherCurl,resistanceBandBicepCurl",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "4c9c5f39f9",
  },
  cableChestPress: {
    key: "cableChestPress",
    name: "Cable Chest Press",
    altNames: "Bench",
    plurName: "Cable Chest Press",
    shortName: "Cable Chest Press",
    primMusc1: "chest",
    primMusc2: "triceps",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableChestPress",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "018d95facb",
  },
  cableChop: {
    key: "cableChop",
    name: "Cable Chop",
    altNames: "",
    plurName: "Cable Chop",
    shortName: "Cable Chop",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableChopLowToHigh",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "8ff22c97ee",
  },
  cableChopHighToLow: {
    key: "cableChopHighToLow",
    name: "Cable Chop (High to Low)",
    altNames: "",
    plurName: "Cable Chop (High to Low)",
    shortName: "Cable Chop (H2L)",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableChopHighToLow",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "fa4d20f8e3",
  },
  cableChopLowToHigh: {
    key: "cableChopLowToHigh",
    name: "Cable Chop (Low to High)",
    altNames: "",
    plurName: "Cable Chop (Low to High)",
    shortName: "Cable Chop (L2H)",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableChopLowToHigh",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "54e1dafe82",
  },
  cableCrunch: {
    key: "cableCrunch",
    name: "Cable Crunch",
    altNames: "",
    plurName: "Cable Crunch",
    shortName: "Cable Crunch",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Cable crunches are an excellent workout with cables to improve core strength. They focus on the sides of your core, also known as your obliques.",
    calibKey: "cableCrunchTraditional",
    "AI Alternative Exercises": "abCrunchMachine,sitUp",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "32a2e7164e",
  },
  cableFacePull: {
    key: "cableFacePull",
    name: "Cable Face Pull",
    altNames: "",
    plurName: "Cable Face Pull",
    shortName: "Cable Face Pull",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Face pulls are an essential upper body exercise which targets the posterior deltoids of the shoulder. To perform this exercise you must use a cable pulley machine to pull the weight straight toward your forehead.",
    calibKey: "cableFacePullRope",
    "AI Alternative Exercises": "vBarPulldown,sitUp,cableSeatedRow",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "8e30c2c2a2",
  },
  cableCrossoverHigh: {
    key: "cableCrossoverHigh",
    name: "Cable Fly (Crossover) High",
    altNames: "",
    plurName: "Cable Fly (Crossover) High",
    shortName: "Cable Fly High",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Cable Crossover is a excellent exercise to help strengthen the muscles of the upper chest. It involves two pulleys and will engage the pectoralis major and the pectoralis minor as well as your anterior deltoids.",
    calibKey: "cableCrossoverHigh",
    "AI Alternative Exercises":
      "cableUprightRearDeltFly,cableRussianTwistLeft,cableCrossoverLow,cableBentoverRearDeltFly",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "a5b94625f4",
  },
  cableCrossoverLow: {
    key: "cableCrossoverLow",
    name: "Cable Fly (Crossover) Low",
    altNames: "",
    plurName: "Cable Fly (Crossover) Low",
    shortName: "Cable Fly Low",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Cable Crossover is a excellent exercise to help strengthen the muscles of the upper chest. It involves two pulleys and will engage the pectoralis major and the pectoralis minor as well as your anterior deltoids.",
    calibKey: "cableCrossoverLow",
    "AI Alternative Exercises": "cableUprightRearDeltFly,bicycleCrunch",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "0663b45591",
  },
  cableCrossoverMid: {
    key: "cableCrossoverMid",
    name: "Cable Fly (Crossover) Mid",
    altNames: "",
    plurName: "Cable Fly (Crossover) Mid",
    shortName: "Cable Fly Mid",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Cable Crossover is a excellent exercise to help strengthen the muscles of the upper chest. It involves two pulleys and will engage the pectoralis major and the pectoralis minor as well as your anterior deltoids.",
    calibKey: "cableCrossoverMid",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "38401bba35",
  },
  cableFrontRaiseSingleArm: {
    key: "cableFrontRaiseSingleArm",
    name: "Cable Front Raise Single Arm",
    altNames: "",
    plurName: "Cable Front Raise Single Arms",
    shortName: "Cable Front Raise",
    primMusc1: "shoulders",
    primMusc2: "lats",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 15,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableFrontRaiseSingleArm",
    "AI Alternative Exercises":
      "dumbbellFrontRaise,cableSingleArmLateralRaise,cableStraightArmPulldown,cableRopeStraightArmPulldown",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "fed20c09a7",
  },
  cablePullThrough: {
    key: "cablePullThrough",
    name: "Cable Pull Through",
    altNames: "",
    plurName: "Cable Pull Throughs",
    shortName: "Cable Pull Through",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 60,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cablePullThrough",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "4cfd70e831",
  },
  cableRopeUprightRow: {
    key: "cableRopeUprightRow",
    name: "Cable Rope Upright Row",
    altNames: "",
    plurName: "Cable Rope Upright Rows",
    shortName: "Cable Rope Upright Row",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableRopeUprightRow",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "23c3802417",
  },
  cableUprightRow: {
    key: "cableUprightRow",
    name: "Cable Upright Row",
    altNames: "",
    plurName: "Cable Upright Row",
    shortName: "Cable Upright Row",
    primMusc1: "shoulders",
    primMusc2: "traps",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 55,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Upright rows are a great exercise that can be completed with a barbell, dumbbells, or cables. They focus on both your shoulders and your traps. ",
    calibKey: "cableUprightRow",
    "AI Alternative Exercises":
      "barbellUprightRow,dumbbellUprightRow,cableCrunch,cableSeatedRow,cableRopeTricepExtension",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "2412886bfd",
  },
  cableFrontRaiseDoubleArm: {
    key: "cableFrontRaiseDoubleArm",
    name: "Cable Front Raise Double Arm",
    altNames: "",
    plurName: "Cable Front Raises Double Arm",
    shortName: "Cbl Front Raise (2-Arm)",
    primMusc1: "shoulders",
    primMusc2: "lats",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableFrontRaiseDoubleArm",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 40,
    hash: "5d01f8dd05",
  },
  cableSingleArmLateralRaise: {
    key: "cableSingleArmLateralRaise",
    name: "Cable Single Arm Lateral Raise",
    altNames: "",
    plurName: "Cable Single Arm Lateral Raises",
    shortName: "Cbl Lat Raise",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "lats",
    exCat: "reps",
    weight: 15,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "A shoulder exercise that workds the middle head of the deltoid muscle, which is the rounded muscle at the very top of each shoulder.",
    calibKey: "cableLatRaiseSingleArm",
    "AI Alternative Exercises":
      "dumbbellLatRaise,cableFrontRaiseSingleArm,dumbbellReverseFly,dumbbellSideRaiseToFrontRaise,cableBentoverRearDeltFly",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "2d28dce158",
  },
  cableSeatedRowWideHammerGrip: {
    key: "cableSeatedRowWideHammerGrip",
    name: "Cable Seated Row (Wide Hammer Grip)",
    altNames: "D-Bar",
    plurName: "Cable Seated Rows Wide Hammer Grip",
    shortName: "Cbl Row (Wide Hammer)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableSeatedRowWideHammerGrip",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "9c734aabfe",
  },
  cableStandingRussianTwist: {
    key: "cableStandingRussianTwist",
    name: "Cable Russian Twist",
    altNames: "",
    plurName: "Cable Russian twists",
    shortName: "Cbl Russian Twist",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Grip the handle with the closest hand, and extend your arm directly over your chest, then place your other hand on top to get a firm grip. Keep your hips high, tighten your midsection, na drotate away from the machine using your core muscles. Your arms should remain straight and extended in the same position throughout the exercise. Slowly return to the starting position, resisting the weight from pulling you back too quickly.",
    calibKey: "cableRussianTwistBothSides",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "36a0776504",
  },
  cableSupermanBicepCurl: {
    key: "cableSupermanBicepCurl",
    name: "Cable Superman Bicep Curl",
    altNames: "",
    plurName: "Cable Superman Bicep Curls",
    shortName: "Cbl Superman Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableBicepCurlSuperman",
    "AI Alternative Exercises":
      "russianTwist,jackknifeSitUp,pullUp,dumbbellInclineFly",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 30,
    hash: "77eb46923f",
  },
  cableOverheadTriExtensionOutBar: {
    key: "cableOverheadTriExtensionOutBar",
    name: "Overhead Tricep Extension High (Bar)",
    altNames: "",
    plurName: "Overhead Tricep Extension High (Bar)",
    shortName: "Cbl Tri Ext. High (Bar)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableOverheadTriExtensionBarHigh",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "4f11d58edf",
  },
  cableRopeOverheadTriExtensionHigh: {
    key: "cableRopeOverheadTriExtensionHigh",
    name: "Overhead Tricep Extension High (Rope)",
    altNames: "",
    plurName: "Overhead Tricep Extension High (Rope)",
    shortName: "Cbl Tri Ext. High (Rope)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableOverheadTriExtensionRopeHigh",
    "AI Alternative Exercises":
      "cableRopeOverheadTriExtensionLow,cableBentoverRearDeltFly,cableFacePull,machineTricepExtension",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "0a3677cc1c",
  },
  cableOverheadTriExtensionOutVBar: {
    key: "cableOverheadTriExtensionOutVBar",
    name: "Overhead Tricep Extension High (V Bar)",
    altNames: "",
    plurName: "Overhead Tricep Extension High (V Bar)",
    shortName: "Cbl Tri Ext. High (V Bar)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableOverheadTriExtensionVBarHigh",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "595be2c734",
  },
  cableOverheadTriExtensionUpBar: {
    key: "cableOverheadTriExtensionUpBar",
    name: "Overhead Tricep Extension Low (Bar)",
    altNames: "",
    plurName: "Overhead Tricep Extension Low (Bar)",
    shortName: "Cbl Tri Ext. Low (Bar)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableOverheadTriExtensionBarLow",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "6458f8f521",
  },
  cableRopeOverheadTriExtensionLow: {
    key: "cableRopeOverheadTriExtensionLow",
    name: "Overhead Tricep Extension Low (Rope)",
    altNames: "",
    plurName: "Overhead Tricep Extension Low (Rope)",
    shortName: "Cbl Tri Ext. Low (Rope)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableOverheadTriExtensionRopeLow",
    "AI Alternative Exercises":
      "cableRopeOverheadTriExtensionHigh,cableRopeTricepExtension",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "4.51E+23",
  },
  cableOverheadTriExtensionUpVBar: {
    key: "cableOverheadTriExtensionUpVBar",
    name: "Overhead Tricep Extension Low (V Bar)",
    altNames: "",
    plurName: "Overhead Tricep Extension Low (V Bar)",
    shortName: "Cbl Tri Ext. Low (V Bar)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableOverheadTriExtensionVBarLow",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "251de4fc13",
  },
  cableExternalShoulderRotation: {
    key: "cableExternalShoulderRotation",
    name: "Cable External Shoulder Rotation",
    altNames: "",
    plurName: "Cable External Shoulder Rotations",
    shortName: "Cbl. Ext. Shoulder Rotation",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableExternalShoulderRotation",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 30,
    hash: "d344aee5a5",
  },
  cableInternalShoulderRotation: {
    key: "cableInternalShoulderRotation",
    name: "Cable Internal Shoulder Rotation",
    altNames: "",
    plurName: "Cable Internal Shoulder Rotations",
    shortName: "Cbl. Int. Shoulder Rotation",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableInternalShoulderRotation",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 30,
    hash: "7b403214b8",
  },
  cableRussianTwistLeft: {
    key: "cableRussianTwistLeft",
    name: "Cable Russian Twist (Left)",
    altNames: "",
    plurName: "Cable Russian Twists (Left)",
    shortName: "Cbl. Russian Twist (Left)",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableRussianTwistLeft",
    "AI Alternative Exercises":
      "cableRussianTwistRight,russianTwist,cableCrossoverHigh,pecDeck",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 50,
    hash: "bf5df79990",
  },
  cableRussianTwistRight: {
    key: "cableRussianTwistRight",
    name: "Cable Russian Twist (Right)",
    altNames: "",
    plurName: "Cable Russian Twists (Right)",
    shortName: "Cbl. Russian Twist (Right)",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableRussianTwistRight",
    "AI Alternative Exercises":
      "cableRussianTwistLeft,cableCrossoverHigh,bicycleCrunch,cableCrossoverLow,russianTwist",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 50,
    hash: "b848501fea",
  },
  machineChestPressSeatedHammer: {
    key: "machineChestPressSeatedHammer",
    name: "Seated Chest Press Machine (Hammer Grip)",
    altNames: "Bench",
    plurName: "Seated Chest Press Machine (Hammer Grip)",
    shortName: "Chest Press Mach. Hammer",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineChestPressSeatedHammer",
    "AI Alternative Exercises":
      "machineRowHammerGrip,machineRow,cableSeatedRow",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 70,
    hash: "ab504de27e",
  },
  seatedChestPressMachine: {
    key: "seatedChestPressMachine",
    name: "Seated Chest Press Machine",
    altNames: "Bench",
    plurName: "Seated Chest Press Machine",
    shortName: "Chest Press Machine",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineChestPressSeatedTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 70,
    hash: "d96346654e",
  },
  chinUp: {
    key: "chinUp",
    name: "Chin Up",
    altNames: "",
    plurName: "Chin Ups",
    shortName: "Chin Ups",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "other",
    tip: "",
    desc: "A Chin up is an essential upper body exercise that focuses primarily on strengthening various muscles on your back and your biceps. To perform a Chin up you must grip an overhead bar with your palms facing towards your boday and lift your body until your chin is over the bar.",
    calibKey: "bodyweightChinUps",
    "AI Alternative Exercises": "hammerPullUp,pullUp",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 80,
    hash: "ea23106eb4",
  },
  barbellClean: {
    key: "barbellClean",
    name: "Clean (Barbell)",
    altNames: "",
    plurName: "Clean (Barbell)",
    shortName: "Clean (Barbell)",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellCleanTraditional",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "6c3fc63331",
  },
  barbellCleanJerk: {
    key: "barbellCleanJerk",
    name: "Clean and Jerk",
    altNames: "",
    plurName: "Clean & Jerk",
    shortName: "Clean and Jerk",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellCleanAndJerk",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "c934146f5b",
  },
  barbellCleanPress: {
    key: "barbellCleanPress",
    name: "Clean and Press",
    altNames: "",
    plurName: "Clean and Press",
    shortName: "Clean and Press",
    primMusc1: "glutes",
    primMusc2: "hamstrings",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Keep core tight and drive through your heels to pull the bar quickly up to your chest, just in front of your collarbone. Be explosive and fast in your movement as you pull the bar, keeping it as close to your body as you can. As soon as the bar reaches your chest, drive through your heels again and press the directly overhead and straighten your arms and legs. Return to starting position with control.",
    calibKey: "barbellCleanAndPress",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "f3644a36e1",
  },
  barbellCloseGripBenchPress: {
    key: "barbellCloseGripBenchPress",
    name: "Close-Grip Bench Press",
    altNames: "",
    plurName: "Close-Grip Bench Press",
    shortName: "Close-Grip Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "bench",
    tip: "",
    desc: "The close-grip bench press is an essential upper body exercise that can be completed with a barbell or dumbbells. It focuses on your chest and triceps. The exercise allows you to build muscle and strength. ",
    calibKey: "barbellBenchPressCloseGrip",
    "AI Alternative Exercises":
      "barbellInclineBenchPress,barbellBenchPress,barbellMilitaryPress,dumbbellBenchPress",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "b2ed7d203c",
  },
  dumbbellConcentrationCurl: {
    key: "dumbbellConcentrationCurl",
    name: "Concentration Curl",
    altNames: "",
    plurName: "Concentration Curls",
    shortName: "Concentration Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Sitting on a bench, slowly curl your weight up, only moving your forearms. Position your upper arm on your thigh to keep still during the exercise.",
    calibKey: "dumbbellConcentrationCurl",
    "AI Alternative Exercises":
      "dumbbellBicepCurl,barbellBicepCurl,ezBarBicepCurl,cableBicepCurl,machinePreacherCurl",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 30,
    hash: "9351ddf98a",
  },
  cableCrossoverLatRaise: {
    key: "cableCrossoverLatRaise",
    name: "Cross Cable Lat Raise",
    altNames: "",
    plurName: "Cross Cable Lat Raises",
    shortName: "Cross Cable Lat Raise",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "lats",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableCrossoverLatRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 60,
    hash: "35b659b8e8",
  },
  crunch: {
    key: "crunch",
    name: "Crunch",
    altNames: "",
    plurName: "Crunches",
    shortName: "Crunches",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "Place your hands on your head, touching your ears for best tracking results.",
    desc: "Crunches are an exercise that help build core strength and tone. They focus on the front of your core, primarily your abs.",
    calibKey: "bodyweightCrunch",
    "AI Alternative Exercises":
      "sitUp,bicycleCrunch,deadBug,jackknifeSitUp,bodyweightSquat",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 80,
    hash: "b12a020b7f",
  },
  dumbbellBenchPress: {
    key: "dumbbellBenchPress",
    name: "Dumbbell Bench Press",
    altNames: "",
    plurName: "Dumbbell Bench Press",
    shortName: "DB Bench Press",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "Dumbbell bench press is an essential upper body exercise that enables you to build muscle and strength in both your chest and triceps.",
    calibKey: "dumbbellBenchPressTraditional",
    "AI Alternative Exercises":
      "dumbbellOverheadPress,dumbbellInclineBenchPress,barbellBenchPress",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 80,
    hash: "ff2523747c",
  },
  dumbbellBulgarianSplitSquat: {
    key: "dumbbellBulgarianSplitSquat",
    name: "Dumbbell Bulgarian Split Squat",
    altNames: "",
    plurName: "Dumbbell Bulgarian Split Squat",
    shortName: "DB Bulg. Split Squat",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Bulgarian split squats are an advanced lower body exercise that can be completed with dumbbells. They place an emphasis on the front of your legs. ",
    calibKey: "dumbbellBulgarianSplitSquat",
    "AI Alternative Exercises":
      "dumbbellWalkingLunge,dumbbellShrug,dumbbellStepUp",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "67cebb5819",
  },
  dumbbellFloorPress: {
    key: "dumbbellFloorPress",
    name: "Dumbbell Floor Press",
    altNames: "",
    plurName: "Dumbbell Floor Presses",
    shortName: "DB Floor Press",
    primMusc1: "chest",
    primMusc2: "triceps",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellFloorPress",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "d02fec999b",
  },
  dumbbellHipThruster: {
    key: "dumbbellHipThruster",
    name: "Dumbbell Hip Thruster",
    altNames: "",
    plurName: "Dumbbell Hip Thrusters",
    shortName: "DB Hip Thrusters",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellHipThruster",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 40,
    hash: "ddd23cdfec",
  },
  dumbbellInclineBenchFrontRaise: {
    key: "dumbbellInclineBenchFrontRaise",
    name: "Dumbbell Incline Bench Front Raise",
    altNames: "",
    plurName: "Dumbbell Incline Bench Front Raise",
    shortName: "DB Inc. Bench Front Raise",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "lats",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellFrontRaiseInclineBench",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "927403646",
  },
  dumbbellInclineBenchReverseFly: {
    key: "dumbbellInclineBenchReverseFly",
    name: "Dumbbell Incline Bench Reverse Fly",
    altNames: "",
    plurName: "Dumbbell Incline Bench Reverse Fly",
    shortName: "DB Inc. Bench Rvs Fly",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "lats",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellReverseFlyInclineBench",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "3dcbeed03f",
  },
  dumbbellInclineBenchY: {
    key: "dumbbellInclineBenchY",
    name: "Dumbbell Incline Bench Y Raise",
    altNames: "",
    plurName: "Dumbbell Incline Bench Y Raise",
    shortName: "DB Inc. Bench Y Raise",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "lats",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellYsInclineBench",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "21b24fb76e",
  },
  dumbbellInclineBenchPress: {
    key: "dumbbellInclineBenchPress",
    name: "Dumbbell Incline Bench Press",
    altNames: "",
    plurName: "Dumbbell Incline Bench Press",
    shortName: "DB Incline Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "The bench press is an essential upper body exercise that can be completed with a barbell or dumbbells. It focuses on your chest and triceps. The exercise allows you to build muscle and strength. ",
    calibKey: "dumbbellBenchPressIncline",
    "AI Alternative Exercises":
      "dumbbellBenchPress,dumbbellOverheadPress,dumbbellInclineFly,barbellInclineBenchPress,barbellCloseGripBenchPress",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "ba9fe08d66",
  },
  dumbbellCloseGripHammerInclineBenchPress: {
    key: "dumbbellCloseGripHammerInclineBenchPress",
    name: "Dumbbell Incline Bench Press Close Grip",
    altNames: "",
    plurName: "Dumbbell Incline Bench Press Close Grip",
    shortName: "DB Incline Bench Press Close Grip",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "dumbbellCloseGripInclineHammerBenchPress",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 50,
    hash: "7a032eecfc",
  },
  dumbbellInclineBenchRow: {
    key: "dumbbellInclineBenchRow",
    name: "Dumbbell Incline Bench Row",
    altNames: "",
    plurName: "Dumbbell Incline Bench Row",
    shortName: "DB Incline Bench Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellRowInclineBench",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 50,
    hash: "da154cb222",
  },
  dumbbellInclineFly: {
    key: "dumbbellInclineFly",
    name: "Dumbbell Incline Fly",
    altNames: "",
    plurName: "Dumbbell Incline Fly",
    shortName: "DB Incline Fly",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "Flys are an isolated movement that focus on the middle of your chest. They can be completed with dumbbells, cables, or plates.",
    calibKey: "dumbbellFlyInclineBench",
    "AI Alternative Exercises":
      "dumbbellFly,barbellBenchPress,sideBend,dumbbellDeadlift",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "7062b52571",
  },
  dumbbellLateralLunge: {
    key: "dumbbellLateralLunge",
    name: "Dumbbell Lateral Lunge",
    altNames: "",
    plurName: "Dumbbell Lateral Lunges",
    shortName: "DB Lateral Lunge",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellLateralLunge",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 2,
    pop: 40,
    hash: "f5a420ee23",
  },
  dumbbellOverheadSquat: {
    key: "dumbbellOverheadSquat",
    name: "Dumbbell Overhead Squat",
    altNames: "",
    plurName: "Dumbbell Overhead Squat",
    shortName: "DB Overhead Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellOverheadSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "69ff605e25",
  },
  dumbbellPreacherCurl: {
    key: "dumbbellPreacherCurl",
    name: "Dumbbell Preacher Curl",
    altNames: "",
    plurName: "Dumbbell Preacher Curls",
    shortName: "DB Preacher Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "dumbbells",
    tip: "",
    desc: "",
    calibKey: "dumbbellPreacherCurl",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 50,
    hash: "d99d1e03b8",
  },
  dumbbellReverseFly: {
    key: "dumbbellReverseFly",
    name: "Dumbbell Rear Delt (Reverse) Fly",
    altNames: "Raise,Bent Over",
    plurName: "Dumbbell Rear Delt (Reverse) Fly",
    shortName: "DB Rear Delt Fly",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Reverse flys are an isolated movement that allow you to build muscle and tone the back of your shoulders. They are completed with dumbbells. ",
    calibKey: "dumbbellRearDeltFlyTraditional",
    "AI Alternative Exercises":
      "dumbbellLatRaise,dumbbellTricepKickback,cableBentoverRearDeltFly,dumbbellFrontRaise,kettlebellSwing",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "8e6b85bd52",
  },
  dumbbellRearDeltRow: {
    key: "dumbbellRearDeltRow",
    name: "Dumbbell Rear Delt Row",
    altNames: "",
    plurName: "Dumbbell Rear Delt Rows",
    shortName: "DB Rear Delt Row",
    primMusc1: "shoulders",
    primMusc2: "lats",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellRearDeltRow",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "a98dfe4d00",
  },
  dumbbellOverheadPress: {
    key: "dumbbellOverheadPress",
    name: "Dumbbell Shoulder Press",
    altNames: "Overhead",
    plurName: "Dumbbell Shoulder Press",
    shortName: "DB Shoulder Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "Overhead press is an essential upper body exercise that is completed with dumbbells. The exercise focuses on your shoulders, and allows you to build both strength and muscle. ",
    calibKey: "dumbbellOverheadPress",
    "AI Alternative Exercises":
      "dumbbellBenchPress,dumbbellInclineBenchPress,seatedOverheadPressMachineHammerGrip,barbellCloseGripBenchPress,vBarPulldown",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "4ff262d585",
  },
  dumbbellSingleArmShoulderPress: {
    key: "dumbbellSingleArmShoulderPress",
    name: "Dumbbell Single Arm Shoulder Press",
    altNames: "Overhead",
    plurName: "Dumbbell Single Arm Shoulder Press",
    shortName: "DB Shoulder Press (1-Arm)",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellSingleArmShoulderPress",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 50,
    hash: "df8c9a1f98",
  },
  dumbbellSkullCrusher: {
    key: "dumbbellSkullCrusher",
    name: "Dumbbell Skull Crusher",
    altNames: "",
    plurName: "Dumbbell Skull Crusher",
    shortName: "DB Skull Crusher",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "Skull crushers are an isolated movement that allow you to build muscle and tone the back of your arms. They are completed with dumbbells. ",
    calibKey: "dumbbellSkullCrusher",
    "AI Alternative Exercises":
      "dumbbellPullover,resistanceBandBicepCurl,cableRopeOverheadTriExtensionLow,ezBarSkullCrusher,machineTricepExtension",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "dd514ce030",
  },
  dumbbellSplitSquat: {
    key: "dumbbellSplitSquat",
    name: "Dumbbell Split Squat",
    altNames: "",
    plurName: "Dumbbell Split Squats",
    shortName: "DB Split Squat",
    primMusc1: "glutes",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellSplitSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 50,
    hash: "fee078ea9b",
  },
  dumbbellOverheadTricepExtensionSingleArm: {
    key: "dumbbellOverheadTricepExtensionSingleArm",
    name: "Dumbbell Single Arm Overhead Tricep Extension",
    altNames: "Press",
    plurName: "Dumbbell Single Arm Overhead Tricep Extension",
    shortName: "DB Tricep Ext. (1-Arm)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellOverheadTricepExtensionSingleArm",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 2,
    pop: 40,
    hash: "341b061265",
  },
  dumbbellTricepPress: {
    key: "dumbbellTricepPress",
    name: "Dumbbell Overhead Tricep Extension",
    altNames: "Press",
    plurName: "Dumbbell Tricep Extension",
    shortName: "DB Tricep Ext.",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Tricep press is an isolated movement that allows you to build muscle and tone the back of your arms. The exercise is completed with dumbbells. ",
    calibKey: "dumbbellTricepPress",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "bdfa7e5105",
  },
  dumbbellUprightRow: {
    key: "dumbbellUprightRow",
    name: "Dumbbell Upright Row",
    altNames: "",
    plurName: "Dumbbell Upright Row",
    shortName: "DB Upright Row",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Upright rows are a great exercise that help build muscle, strength, and tone your shoulders and traps. They are completed with dumbbells. ",
    calibKey: "dumbbellUprightRow",
    "AI Alternative Exercises":
      "barbellUprightRow,dumbbellRow,cableUprightRow,barbellLandmineSplitSquat,bodyweightSquat",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "e3b613e16b",
  },
  dumbbellVSit: {
    key: "dumbbellVSit",
    name: "Dumbbell V Sit",
    altNames: "",
    plurName: "Dumbbell V Sit",
    shortName: "DB V Sit",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellVSit",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "8c66bb1021",
  },
  deadBug: {
    key: "deadBug",
    name: "Dead Bug",
    altNames: "",
    plurName: "Dead Bug",
    shortName: "Dead Bug",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Deadbugs are a great core exercise that improve core strength, stability, and overall coordination. They focus on the sides of your core, also known as your obliques.",
    calibKey: "bodyweightDeadBug",
    "AI Alternative Exercises": "jackknifeSitUp",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "b503cdaef6",
  },
  barbellDeclineBenchPress: {
    key: "barbellDeclineBenchPress",
    name: "Decline Barbell Bench Press",
    altNames: "Chest",
    plurName: "Decline Barbell Bench Press",
    shortName: "Decline BB Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "barbellBenchPressDecline",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "98cd68e978",
  },
  dumbbellDeclineBenchPress: {
    key: "dumbbellDeclineBenchPress",
    name: "Decline Dumbbell Bench Press",
    altNames: "Chest",
    plurName: "Decline Dumbbell Bench Press",
    shortName: "Decline DB Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "dumbbellBenchPressDecline",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 50,
    hash: "9aceb032fe",
  },
  declinePushUp: {
    key: "declinePushUp",
    name: "Decline Push Up",
    altNames: "",
    plurName: "Decline Push Up",
    shortName: "Decline Push Up",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPushUpDecline",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "7acb4ce3ed",
  },
  diamondPushUp: {
    key: "diamondPushUp",
    name: "Diamond Push Up",
    altNames: "",
    plurName: "Diamond Push Ups",
    shortName: "Diamond Push Ups",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "shoulders",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Push ups are an essential upper body exercise that engage your entire upper body, with an emphasis on your chest and triceps. ",
    calibKey: "bodyweightPushUpDiamond",
    "AI Alternative Exercises": "benchDips,pushUp",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "c795d72f2b",
  },
  dips: {
    key: "dips",
    name: "Dips",
    altNames: "",
    plurName: "Dips",
    shortName: "Dips",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "chest",
    secMusc2: "shoulders",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "other",
    tip: "",
    desc: "Dips are an essential upper body strength exercise that primarily focuses on triceps and anterior deltoids. This can be performed either with a bench/raised platform or a dip rack.",
    calibKey: "bodyweightDips",
    "AI Alternative Exercises": "benchDips,pullUp",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "9b9a2cbe44",
  },
  donkeyKicks: {
    key: "donkeyKicks",
    name: "Donkey Kicks",
    altNames: "",
    plurName: "Donkey Kicks",
    shortName: "Donkey Kicks",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightDonkeyKicks",
    "AI Alternative Exercises": "",
    calCoef: 0.85,
    TVLFactor: 1,
    pop: 50,
    hash: "6654e032e4",
  },
  doubleCableLatPulldown: {
    key: "doubleCableLatPulldown",
    name: "Lat Pull Down Double Handles",
    altNames: "",
    plurName: "Lat Pull Down Double Handles",
    shortName: "Double Handle Lat Pull Down",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 95,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "The Lat pulldown is a compound exercise designed to target many muscles of the back, most notably the latissimus dorsi. This exercise can be completed using a lat pulldown bar, a D-handle bar, a chamption pro-style lat bar, or a V-bar.",
    calibKey: "cableLatPulldownDBar",
    "AI Alternative Exercises":
      "latPulldown,abRollOut,dumbbellRenegadeRow,chinUp,reverseGripLatPulldown",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "71dc8b3eb9",
  },
  dumbbellCalfRaise: {
    key: "dumbbellCalfRaise",
    name: "Dumbbell Calf Raise",
    altNames: "Extension",
    plurName: "Dumbbell Calf Raise",
    shortName: "Dumbbell Calf Raise",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellCalfRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 50,
    hash: "6abb455b82",
  },
  dumbbellClean: {
    key: "dumbbellClean",
    name: "Dumbbell Clean",
    altNames: "",
    plurName: "Dumbbell Cleans",
    shortName: "Dumbbell Clean",
    primMusc1: "glutes",
    primMusc2: "hamstrings",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Drive cumbbell up with legs, once weight passes knees, explosively extend hips and use momentum to carry weight up. Once hips are fully extended, quickly drive body under and catch the dumbbell in the front rack position, with elbows as high as possible and in a partial quarter squat.",
    calibKey: "dumbbellCleanTraditional",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 20,
    hash: "c7bb90a01a",
  },
  dumbbellDeadlift: {
    key: "dumbbellDeadlift",
    name: "Dumbbell Deadlift",
    altNames: "",
    plurName: "Dumbbell Deadlifts",
    shortName: "Dumbbell Deadlifts",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Dumbbell deadlifts are an essential exercise that help build both strength, and muscle. The exercise focuses on your whole body, with an emphasis on your lower back.  ",
    calibKey: "dumbbellDeadliftTraditional",
    "AI Alternative Exercises":
      "dumbbellBulgarianSplitSquat,sideBend,barbellDeadlift,dumbbellShrug,trapBarDeadlift",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "ab692df681",
  },
  dumbbellFly: {
    key: "dumbbellFly",
    name: "Dumbbell Fly",
    altNames: "Chest",
    plurName: "Dumbbell Flys",
    shortName: "Dumbbell Flys",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "Flys are an isolated movement that build muscle and tone the middle of your chest. They are completed with dumbbells. ",
    calibKey: "dumbbellFly",
    "AI Alternative Exercises":
      "dumbbellInclineFly,barbellCloseGripBenchPress,dumbbellBenchPress",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "fe0ca48642",
  },
  dumbbellForearmCurl: {
    key: "dumbbellForearmCurl",
    name: "Dumbbell Forearm Curl",
    altNames: "Wrist",
    plurName: "Dumbbell Forearm Curls",
    shortName: "Dumbbell Forearm Curl",
    primMusc1: "forearms",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellForearmCurl",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 30,
    hash: "291b18753b",
  },
  dumbbellLunge: {
    key: "dumbbellLunge",
    name: "Dumbbell Lunge",
    altNames: "",
    plurName: "Dumbbell Lunges",
    shortName: "Dumbbell Lunges",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "quads",
    secMusc2: "calves",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Lunges are an essential lower body exercise that helps build stability, strength, and muscle. They can be completed with dumbbells, a barbell, cables, or without any equipment. They focuses on your lower body, with an emphasis on the front of your legs.",
    calibKey: "dumbbellLungeTraditional",
    "AI Alternative Exercises":
      "dumbbellWalkingLunge,dumbbellBulgarianSplitSquat,barbellBackSquat,barbellLunge,dumbbellStepUp",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "a3aa32395c",
  },
  dumbbellPullover: {
    key: "dumbbellPullover",
    name: "Dumbbell Pullover",
    altNames: "Lat",
    plurName: "Dumbbell Pullovers",
    shortName: "Dumbbell Pullovers",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "chest",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "Dumbbell pullover is a critical resistance exercise that primarily strengthens the muscles in the chest and in the back. ",
    calibKey: "dumbbellPullover",
    "AI Alternative Exercises":
      "dumbbellSkullCrusher,ezBarSkullCrusher,cableRopeBicepCurl,ezBarBicepCurl,machinePreacherCurl",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "ae23904a67",
  },
  dumbbellRow: {
    key: "dumbbellRow",
    name: "Dumbbell Row",
    altNames: "",
    plurName: "Dumbbell Row",
    shortName: "Dumbbell Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Dumbbell rows are an essential upper body exercise that focus on your lats and biceps. The exercise allows you to build both muscle and strength. ",
    calibKey: "dumbbellRowTraditional",
    "AI Alternative Exercises":
      "barbellBentoverRow,dumbbellUprightRow,dumbbellReverseFly,barbellUprightRow,dumbbellRenegadeRow,benchSupportedDumbbellRow",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "f6254302b2",
  },
  dumbbellShrug: {
    key: "dumbbellShrug",
    name: "Dumbbell Shrug",
    altNames: "",
    plurName: "Dumbbell Shrugs",
    shortName: "Dumbbell Shrugs",
    primMusc1: "traps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "The Shoulder Shrug is a critical exercise in strengthening the shoulders and developing the upper trapezius muscle. It can be performed with a barbell, dumbbells, trap bar, resistance bands, parallel bar, or a smith machine. To effectively shoulder shrug you must stand tall with hands placed shoulder width apart, and slowly raise the shoulders as high as possible, before slowly lowering them back to starting position.",
    calibKey: "dumbbellShrugStanding",
    "AI Alternative Exercises":
      "trapBarShrug,barbellShrug,trapBarDeadlift,dumbbellBulgarianSplitSquat,barbellBulgarianSplit",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 70,
    hash: "c77b50cab0",
  },
  dumbbellSnatch: {
    key: "dumbbellSnatch",
    name: "Dumbbell Snatch",
    altNames: "",
    plurName: "Dumbbell Snatches",
    shortName: "Dumbbell Snatch",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Drive dumbbell up with legs, once bar paases knees, explosively extend hips and use momentum to carrythe weight up. Once hips are fully extended, quickly drive body under and catch the dumbbell in the overhead, with elbows as high as possible and in a partial quarter squat.",
    calibKey: "dumbbellSnatch",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 20,
    hash: "2366df3086",
  },
  dumbbellStepUp: {
    key: "dumbbellStepUp",
    name: "Dumbbell Step Up",
    altNames: "",
    plurName: "Dumbbell Step Up",
    shortName: "Dumbbell Step Up",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "quads",
    secMusc2: "calves",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Step ups are an effective unilateral exercise that helps to improve symmetry, balance, and coordination, while developing strength and power in the quadriceps, hamstrings, glutes, and calves. They can be performed with or without dumbbells and with a step of some sort.",
    calibKey: "dumbbellStepUp",
    "AI Alternative Exercises":
      "dumbbellBulgarianSplitSquat,gobletSquat,dumbbellShrug",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 40,
    hash: "85c5355384",
  },
  machineElliptical: {
    key: "machineElliptical",
    name: "Elliptical",
    altNames: "Machine",
    plurName: "machineElliptical",
    shortName: "Elipitcal",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "distance",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineElliptical",
    "AI Alternative Exercises": "",
    calCoef: 0.1,
    TVLFactor: 1,
    pop: 70,
    hash: "66e64596c5",
  },
  eZBarBenchPress: {
    key: "eZBarBenchPress",
    name: "EZ Bar Bench Press",
    altNames: "",
    plurName: "EZ Bar Bench Press",
    shortName: "EZ Bar Bench Press",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "ezBar",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "ezBarBenchPress",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "5e70165432",
  },
  eZBarCloseGripBenchPress: {
    key: "eZBarCloseGripBenchPress",
    name: "EZ Bar Close Grip Bench Press",
    altNames: "",
    plurName: "EZ Bar Close Grip Bench Press",
    shortName: "EZ Bar Close Grip Bench",
    primMusc1: "triceps",
    primMusc2: "chest",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "ezBar",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "ezBarCloseGripBenchPress",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "bfeecd2d40",
  },
  eZBarUprightRow: {
    key: "eZBarUprightRow",
    name: "EZ Bar Upright Row",
    altNames: "",
    plurName: "EZ Bar Upright Row",
    shortName: "EZ Bar Upright Row",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "ezBar",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "ezBarUprightRow",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 30,
    hash: "f008ac63f3",
  },
  ezBarBicepCurl: {
    key: "ezBarBicepCurl",
    name: "EZ-Bar Bicep Curl",
    altNames: "",
    plurName: "EZ-Bar Bicep Curl",
    shortName: "EZ-Bar Bicep Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 60,
    equip1: "ezBar",
    equip2: "",
    tip: "",
    desc: "Bicep curls are an isolated movement that can be completed with a barbell, dumbbells, cables, EZ-bar or resistance bands. They focus on the front of your arm. ",
    calibKey: "ezBarBicepCurlTraditional",
    "AI Alternative Exercises":
      "barbellBicepCurl,cableBicepCurl,ezBarPreacherCurl,cableRopeBicepCurl,dumbbellBicepCurl",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "1e79dab686",
  },
  ezBarPreacherCurl: {
    key: "ezBarPreacherCurl",
    name: "EZ-Bar Preacher Curl",
    altNames: "",
    plurName: "EZ-Bar Preacher Curl",
    shortName: "EZ-Bar Preacher Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 55,
    equip1: "ezBar",
    equip2: "other",
    tip: "",
    desc: "Preacher curls are an effective upper body exercise that primarily targets the biceps. They can be completed using either dumbbells, a barbell, or a machine. ",
    calibKey: "ezBarPreacherCurl",
    "AI Alternative Exercises":
      "machinePreacherCurl,ezBarBicepCurl,barbellBicepCurl,cableBicepCurl,ezBarSkullCrusher",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "3753e6e73f",
  },
  eZBarReverseCurl: {
    key: "eZBarReverseCurl",
    name: "Reverse Grip EZ-Bar Bicep Curls",
    altNames: "",
    plurName: "Reverse Grip EZ-Bar Bicep Curls",
    shortName: "EZ-Bar Rvs. Grip Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "ezBar",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "ezBarBicepCurlReverse",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "d0734900ef",
  },
  ezBarSkullCrusher: {
    key: "ezBarSkullCrusher",
    name: "EZ-Bar Skullcrusher",
    altNames: "",
    plurName: "EZ-Bar Skullcrusher",
    shortName: "EZ-Bar Skullcrusher",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "ezBar",
    equip2: "bench",
    tip: "",
    desc: "Skullcrushers are an isolated movement that can be completed with dumbbells, cables, or resistance bands. They focus on the back of your arm. ",
    calibKey: "ezBarSkullCrusher",
    "AI Alternative Exercises": "dumbbellSkullCrusher",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "6988715d2d",
  },
  dumbbellFarmersWalk: {
    key: "dumbbellFarmersWalk",
    name: "Farmers Walk (Weighted)",
    altNames: "",
    plurName: "Farmers Walk (Weighted)",
    shortName: "Farmers Walk",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "traps",
    secMusc2: "",
    exCat: "distance",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellFarmersCarry",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 2,
    pop: 20,
    hash: "ab95f49760",
  },
  flutterKick: {
    key: "flutterKick",
    name: "Flutter Kick",
    altNames: "",
    plurName: "Flutter Kicks",
    shortName: "Flutter Kick",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Flutter kicks are an exercise that works the muscles of your core, specifically the lower rectus abdominal muscles, plus the hip flexors.",
    calibKey: "bodyweightFlutterKick",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "77296f5eb7",
  },
  dumbbellFrontRaise: {
    key: "dumbbellFrontRaise",
    name: "Front Raise",
    altNames: "",
    plurName: "Front Raises",
    shortName: "Front Raises",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Front raises are an isolated movement that focus on the front of your shoulders by building muscle and toning. They are completed with dumbbells.",
    calibKey: "dumbbellFrontRaiseStanding",
    "AI Alternative Exercises":
      "cableFrontRaiseSingleArm,dumbbellLatRaise,dumbbellSideRaiseToFrontRaise,plateFrontRaise,cableStraightArmPulldown",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "401f28cb8d",
  },
  barbellFrontSquat: {
    key: "barbellFrontSquat",
    name: "Front Squat",
    altNames: "",
    plurName: "Front Squat",
    shortName: "Front Squat",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "quads",
    secMusc2: "calves",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Front squats are a very effective lower body exercise that strengthens your glutes, hamstrings, and quadriceps. It can be completed by holding the barbell in the front of your body across the clavicles and deltoids in either a clean grip or with the arms crossed and hands placed on top of the barbell.",
    calibKey: "barbellFrontSquatTraditional",
    "AI Alternative Exercises":
      "barbellBackSquat,bodyweightSquat,barbellBulgarianSplit,tBarRow",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "2c4279007c",
  },
  bodyweightGluteBridgeSingleLeg: {
    key: "bodyweightGluteBridgeSingleLeg",
    name: "Single Leg Glute Bridge ",
    altNames: "",
    plurName: "bodyweightGluteBridgeSingleLeg",
    shortName: "Glute Bridge (Single Leg)",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightGluteBridgeSingleLeg",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "4fcafa1f7f",
  },
  bodyweightGluteKickback: {
    key: "bodyweightGluteKickback",
    name: "Glute Kickback (Bodyweight) ",
    altNames: "",
    plurName: "Glute Kickbacks (Bodyweight)",
    shortName: "Glute Kickbacks (Bodyweight)",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightGluteKickback",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "2c0f0207d6",
  },
  cableGluteKickBack: {
    key: "cableGluteKickBack",
    name: "Glute Kickback (Cable)",
    altNames: "",
    plurName: "Glute Kickbacks (Cable) ",
    shortName: "Glute Kickbacks (Cable) ",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableGluteKickBack",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 2,
    pop: 40,
    hash: "2172abf3cd",
  },
  glutePushDown: {
    key: "glutePushDown",
    name: "Glute Push Down (Assisted Pull-up Machine)",
    altNames: "",
    plurName: "Glute Push Downs",
    shortName: "Glute Push Down",
    primMusc1: "glutes",
    primMusc2: "hamstrings",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "glutePushDown",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 40,
    hash: "4a19468915",
  },
  gobletSquat: {
    key: "gobletSquat",
    name: "Goblet Squat",
    altNames: "",
    plurName: "Goblet Squats",
    shortName: "Goblet Squats",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Goblet squats are a lower body exercise that help build stability, strength, and muscle. They can be completed with a dumbbell or kettlebell. The exercise focuses on your lower body, with an emphasis on the front of your legs.",
    calibKey: "dumbbellGobletSquat",
    "AI Alternative Exercises":
      "bodyweightSquat,barbellLunge,cableCrunch,reverseGripLatPulldown",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "24ca56ce0b",
  },
  barbellGoodMorning: {
    key: "barbellGoodMorning",
    name: "Good Morning",
    altNames: "",
    plurName: "Good Morning",
    shortName: "Good Morning",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellGoodMorning",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "5e35432a75",
  },
  gorillaRows: {
    key: "gorillaRows",
    name: "Gorilla Row",
    altNames: "",
    plurName: "Gorilla Rows",
    shortName: "Gorilla Rows",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "gorillaRows",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 40,
    hash: "d7a90a68a6",
  },
  machineHackSquat: {
    key: "machineHackSquat",
    name: "Hack Squat (Facing Out)",
    altNames: "Machine",
    plurName: "Hack Squats (Facing Out)",
    shortName: "Hack Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineHackSquatFacingOut",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "0978145dbe",
  },
  dumbbellHammerCurl: {
    key: "dumbbellHammerCurl",
    name: "Hammer Curl",
    altNames: "",
    plurName: "Hammer Curls",
    shortName: "Hammer Curls",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "forearms",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Hammer curls are an isolated movement that allow you to build muscle and tone the front of your arms. They are completed with dumbbells. ",
    calibKey: "dumbbellBicepCurlHammerTraditional",
    "AI Alternative Exercises":
      "cableRopeBicepCurl,cableRopeTricepExtension,dumbbellBicepCurl,dumbbellSkullCrusher",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 80,
    hash: "1bbae50800",
  },
  hammerPullUp: {
    key: "hammerPullUp",
    name: "Hammer Grip Pull Up",
    altNames: "",
    plurName: "Hammer Grip Pull Ups",
    shortName: "Hammer Pull Ups",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "other",
    tip: "",
    desc: "A pull up is an essential upper body exercise that focuses primarily on strengthening various muscles on your back. To perform a Pull up you must grip an overhead bar, with hands perpindicular to your chest and lift your body until your chin is over the bar.",
    calibKey: "bodyweightPullUpHammer",
    "AI Alternative Exercises": "pullUp,chinUp,dips",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "729652ae67",
  },
  cableHandlesBicepCurl: {
    key: "cableHandlesBicepCurl",
    name: "Cable Bicep Curl (Handles)",
    altNames: "",
    plurName: "Cable Bicep Curl (Handles)",
    shortName: "Handles Bicep Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Bicep curls are an isolated movement that can be completed with a barbell, dumbbells, cables, or resistance bands. They focus on the front of your arm. ",
    calibKey: "cableBicepCurlHandles",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 60,
    hash: "6fd47dc7cb",
  },
  handStand: {
    key: "handStand",
    name: "Handstand",
    altNames: "",
    plurName: "Handstand",
    shortName: "Handstand",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightHandstand",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "5428c1078e",
  },
  handstandPushUp: {
    key: "handstandPushUp",
    name: "Handstand Push Up",
    altNames: "",
    plurName: "Handstand Push Up",
    shortName: "Handstand Push Up",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightHandstandPushUp",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 10,
    hash: "e5855c0177",
  },
  barbellHangClean: {
    key: "barbellHangClean",
    name: "Hang Clean",
    altNames: "",
    plurName: "Hang Clean",
    shortName: "Hang Clean",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellHangClean",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "172e0ae8f6",
  },
  bodyweightHangingKneeRaise: {
    key: "bodyweightHangingKneeRaise",
    name: "Hanging Knee Raise",
    altNames: "",
    plurName: "Hanging Knee Raises",
    shortName: "Hanging Knee Raise",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightHangingKneeRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "d0d6eba088",
  },
  hangingLegRaise: {
    key: "hangingLegRaise",
    name: "Hanging Leg Raise",
    altNames: "",
    plurName: "Hanging Leg Raises",
    shortName: "Hanging Leg Raise",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightHangingLegRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "d8673d0fae",
  },
  highKnees: {
    key: "highKnees",
    name: "High Knees",
    altNames: "",
    plurName: "High Knees",
    shortName: "High Knees",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightHighKnees",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "31a223b483",
  },
  machineHipAbduction: {
    key: "machineHipAbduction",
    name: "Machine Hip Abduction",
    altNames: "",
    plurName: "Machine Hip Abduction",
    shortName: "Hip Abduction",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineHipAbduction",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "6bd79868b8",
  },
  machineHipAdduction: {
    key: "machineHipAdduction",
    name: "Machine Hip Adduction",
    altNames: "",
    plurName: "Machine Hip Adduction",
    shortName: "Hip Adduction",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineHipAdduction",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "ba5beb3c40",
  },
  barbellHipThrusters: {
    key: "barbellHipThrusters",
    name: "Hip Thrusters (Barbell Glute Bridge)",
    altNames: "",
    plurName: "Hip Thrusters (Barbell Glute Bridge)",
    shortName: "Hip Thrusters (Barbell Glute Bridge)",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "bench",
    tip: "",
    desc: "Hip thrusters are a variation of a glute bridge that is performed using a barbell and with the body lifted off the floor. This exercise strengthens the lower body and targets the gluteal muscles very effectively.",
    calibKey: "barbellHipThrusters",
    "AI Alternative Exercises": "barbellBackSquat,barbellLandmineSplitSquat",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "734af2e8fa",
  },
  inOut: {
    key: "inOut",
    name: "In And Out",
    altNames: "",
    plurName: "In And Outs",
    shortName: "In And Out",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightInAndOut",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "9e56bfeeb2",
  },
  barbellInclineBenchPress: {
    key: "barbellInclineBenchPress",
    name: "Barbell Incline Bench Press",
    altNames: "",
    plurName: "Barbell Incline Bench Press",
    shortName: "Incline Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "bench",
    tip: "",
    desc: "The bench press is an essential upper body exercise that can be completed with a barbell or dumbbells. It focuses on your chest and triceps. The exercise allows you to build muscle and strength. ",
    calibKey: "barbellBenchPressIncline",
    "AI Alternative Exercises":
      "barbellBenchPress,barbellMilitaryPress,barbellCloseGripBenchPress,seatedOverheadPressMachineRegularGrip",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "f586e89fc7",
  },
  dumbbellInclineBicepCurl: {
    key: "dumbbellInclineBicepCurl",
    name: "Incline Dumbbell Bicep Curl",
    altNames: "",
    plurName: "Incline Dumbbell Bicep Curls",
    shortName: "Incline Bicep Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellInclineBicepCurl",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 50,
    hash: "ab69be7a69",
  },
  dumbbellInclineHammerCurl: {
    key: "dumbbellInclineHammerCurl",
    name: "Incline Dumbbell Hammer Curl",
    altNames: "",
    plurName: "Incline Dumbbell Hammer Curls",
    shortName: "Incline Hammer Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellInclineHammerCurl",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 50,
    hash: "20b506d165",
  },
  inclinePushUp: {
    key: "inclinePushUp",
    name: "Incline Push Up",
    altNames: "",
    plurName: "Incline Push Up",
    shortName: "Incline Push Up",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPushUpIncline",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 20,
    hash: "2e5de943bf",
  },
  jackknifeSitUp: {
    key: "jackknifeSitUp",
    name: "Jackknife Sit Up",
    altNames: "",
    plurName: "Jackknife Sit ups",
    shortName: "Jackknife Sit Up",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "The jackknife can be done by lying flat on your back with your arms extended overhead and your feet raised slightly above the floor. The jackknife is completed by slowly bringing your straight arms toward your hips and lifting your upper torso off the floor.",
    calibKey: "bodyweightJackknifeSitUp",
    "AI Alternative Exercises": "deadBug",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "0fd4e3ff8d",
  },
  jumpRope: {
    key: "jumpRope",
    name: "Jump Rope ",
    altNames: "",
    plurName: "Jump Rope ",
    shortName: "Jump Rope ",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "quads",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "jumpRope",
    "AI Alternative Exercises": "",
    calCoef: 0.1,
    TVLFactor: 1,
    pop: 40,
    hash: "9240676269",
  },
  bodyweightSquatJumps: {
    key: "bodyweightSquatJumps",
    name: "Bodyweight Jump Squat",
    altNames: "",
    plurName: "Bodyweight Jump Squat",
    shortName: "Jump Squats",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightJumpSquat",
    "AI Alternative Exercises": "boxJump,bodyweightSquat,wallBalls",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 20,
    hash: "731189de44",
  },
  jumpingJack: {
    key: "jumpingJack",
    name: "Jumping Jacks",
    altNames: "",
    plurName: "Jumping Jacks",
    shortName: "Jumping Jacks",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Jumping jacks are a great whole body workout. They allow you raise your heart rate while remaining in one area. ",
    calibKey: "bodyweightJumpingJack",
    "AI Alternative Exercises":
      "boxJump,battleRopes,bodyweightSquatJumps,toeTouches",
    calCoef: 0.2,
    TVLFactor: 1,
    pop: 60,
    hash: "88d046cde0",
  },
  kettlebellRomanianDeadlift: {
    key: "kettlebellRomanianDeadlift",
    name: "Kettlebell Romanian Deadlift",
    altNames: "RDL",
    plurName: "Kettlebell Romanian Deadlift",
    shortName: "Kettlebell RDL",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 60,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellRomanianDeadliftDouble",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "5a8ab5fea5",
  },
  kettlebellShrug: {
    key: "kettlebellShrug",
    name: "Kettlebell Shrug",
    altNames: "",
    plurName: "Kettlebell Shrug",
    shortName: "Kettlebell Shrug",
    primMusc1: "traps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellShrugTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 2,
    pop: 30,
    hash: "0e8b714966",
  },
  kettlebellSingleLegRomanianDeadlift: {
    key: "kettlebellSingleLegRomanianDeadlift",
    name: "Kettlebell Single Leg Romanian Deadlift",
    altNames: "RDL",
    plurName: "Kettlebell Single Leg Romanian Deadlift",
    shortName: "Kettlebell Single Leg RDL",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellRomanianDeadliftSingleLeg",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "e50f85845d",
  },
  kettlebellSquat: {
    key: "kettlebellSquat",
    name: "Kettlebell Squat",
    altNames: "",
    plurName: "Kettlebell Squats",
    shortName: "Kettlebell Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellSquatTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "30cbdad9b8",
  },
  kettlebellSwing: {
    key: "kettlebellSwing",
    name: "Kettlebell Swing",
    altNames: "",
    plurName: "Kettlebell Swings",
    shortName: "Kettlebell Swings",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "shoulders",
    secMusc2: "abs",
    exCat: "reps",
    weight: 40,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "Kettlebell Swing is a critical exercise to increase power, muscular endurance, aerobic capacity, and anaerobic capacity. To complete you must you a kettlebell of desired weight.",
    calibKey: "kettlebellSwingTraditional",
    "AI Alternative Exercises":
      "dumbbellFrontRaise,cableStraightArmPulldown,dumbbellReverseFly",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "f3fe7bd368",
  },
  kippingPullUp: {
    key: "kippingPullUp",
    name: "Kipping Pull Up",
    altNames: "",
    plurName: "Kipping Pull Up",
    shortName: "Kipping Pull Up",
    primMusc1: "lats",
    primMusc2: "abs",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPullUpKipping",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "3c2a51589e",
  },
  captainsChairKneeRaise: {
    key: "captainsChairKneeRaise",
    name: "Captain's Chair Knee Raise",
    altNames: "",
    plurName: "Captain's Chair Knee Raise",
    shortName: "Knee Raise",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "machine",
    equip2: "bodyWeight",
    tip: "",
    desc: "",
    calibKey: "captainsChairKneeRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "28249bf247",
  },
  kettlebellClean: {
    key: "kettlebellClean",
    name: "Kettlebell Clean",
    altNames: "",
    plurName: "Kettlebell Clean",
    shortName: "Ktlbl Clean",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellCleanTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "39186efe3e",
  },
  kettlebellCleanPress: {
    key: "kettlebellCleanPress",
    name: "Kettlebell Clean Press",
    altNames: "",
    plurName: "Kettlebell Clean Press",
    shortName: "Ktlbl Clean Press",
    primMusc1: "quads",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 35,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellCleanAndPress",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "4f86e1bd2c",
  },
  kettlebellDeadlift: {
    key: "kettlebellDeadlift",
    name: "Kettlebell Deadlift",
    altNames: "",
    plurName: "Kettlebell Deadlift",
    shortName: "Ktlbl Deadlift",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 60,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellDeadliftDoubleKettlebell",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "4401d267ac",
  },
  kettlebellGluteBridge: {
    key: "kettlebellGluteBridge",
    name: "Kettlebell Glute Bridge",
    altNames: "Hip Thruster",
    plurName: "kettlebellGluteBridge",
    shortName: "Ktlbl Glute Bridge",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellGluteBridge",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "c66f7608db",
  },
  kettlebellGoodMorning: {
    key: "kettlebellGoodMorning",
    name: "Kettlebell Good Morning",
    altNames: "",
    plurName: "Kettlebell Good Morning",
    shortName: "Ktlbl Good Morning ",
    primMusc1: "lowerBack",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellGoodMorning",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "a9f606b119",
  },
  kettlebellHalo: {
    key: "kettlebellHalo",
    name: "Kettlebell Halo",
    altNames: "",
    plurName: "Kettlebell Halo",
    shortName: "Ktlbl Halo",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellHalo",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "ac461fce8d",
  },
  kettlebellLunge: {
    key: "kettlebellLunge",
    name: "Kettlebell Lunge",
    altNames: "",
    plurName: "Kettlebell Lunge",
    shortName: "Ktlbl Lunge",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 35,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellLungeDouble",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "ffdafacf26",
  },
  kettlebellRow: {
    key: "kettlebellRow",
    name: "Kettlebell Row",
    altNames: "",
    plurName: "Kettlebell Row",
    shortName: "Ktlbl Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellRowTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "74704d3aeb",
  },
  kettlebellShoulderPress: {
    key: "kettlebellShoulderPress",
    name: "Kettlebell Shoulder Press",
    altNames: "Overhead Press",
    plurName: "Kettlebell Shoulder Press",
    shortName: "Ktlbl Shoulder Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellShoulderPressDoubleBells",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "f7758d5c8e",
  },
  kettlebellSingleLegDeadlift: {
    key: "kettlebellSingleLegDeadlift",
    name: "Kettlebell Single Leg Deadlift",
    altNames: "",
    plurName: "Kettlebell Single Leg Deadlift",
    shortName: "Ktlbl Single Leg DL",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellDeadliftSingleLeg",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "81df2e1ef0",
  },
  kettlebellSnatch: {
    key: "kettlebellSnatch",
    name: "Kettlebell Snatch",
    altNames: "",
    plurName: "Kettlebell Snatch",
    shortName: "Ktlbl Snatch",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 35,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellSnatch",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 20,
    hash: "f99d240290",
  },
  kettlebellSumoSquat: {
    key: "kettlebellSumoSquat",
    name: "Kettlebell Sumo Squat",
    altNames: "",
    plurName: "Kettlebell Sumo Squats",
    shortName: "Ktlbl Sumo Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellSumoSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "35a555a12c",
  },
  kettlebellThruster: {
    key: "kettlebellThruster",
    name: "Kettlebell Thruster",
    altNames: "",
    plurName: "Kettlebell Thruster",
    shortName: "Ktlbl Thruster",
    primMusc1: "shoulders",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 70,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellThrusterSingle",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "16836b6ab0",
  },
  kettlebellTurkishGetup: {
    key: "kettlebellTurkishGetup",
    name: "Kettlebell Turkish Get Up",
    altNames: "",
    plurName: "Kettlebell Turkish Get Up",
    shortName: "Ktlbl Turkish Getup",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellTurkishGetUpTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 30,
    hash: "134b2c7ee3",
  },
  barbellLandmineDeadlift: {
    key: "barbellLandmineDeadlift",
    name: "Landmine Deadlift",
    altNames: "",
    plurName: "Landmine Deadlifts",
    shortName: "Landmine Deadlift",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellLandmineDeadlift",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "76f72f6272",
  },
  barbellLandminePress: {
    key: "barbellLandminePress",
    name: "Landmine Press",
    altNames: "",
    plurName: "Landmine Press",
    shortName: "Landmine Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellLandminePressSingleArm",
    "AI Alternative Exercises": "bodyweightLunge,barbellDoubleArmLandminePress",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "100721f56e",
  },
  barbellLandmineRow: {
    key: "barbellLandmineRow",
    name: "Landmine Row",
    altNames: "",
    plurName: "Landmine Rows",
    shortName: "Landmine Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellLandmineRow",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "219734bfe1",
  },
  barbellLandmineSplitSquat: {
    key: "barbellLandmineSplitSquat",
    name: "Landmine Split Squat",
    altNames: "",
    plurName: "Landmine Split Squats",
    shortName: "Landmine Split Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellLandmineSplitSquat",
    "AI Alternative Exercises": "landmineSquatPressDoubleArm",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 30,
    hash: "fc9217b5db",
  },
  landmineSquat: {
    key: "landmineSquat",
    name: "Landmine Squat",
    altNames: "",
    plurName: "Landmine Squats",
    shortName: "Landmine Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellLandmineSquat",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 30,
    hash: "a75b2ebed9",
  },
  landmineSquatPressDoubleArm: {
    key: "landmineSquatPressDoubleArm",
    name: "Landmine Double Arm Squat Press",
    altNames: "",
    plurName: "Landmine Double Arm Squat Press",
    shortName: "Landmine Squat Press",
    primMusc1: "shoulders",
    primMusc2: "quads",
    secMusc1: "triceps",
    secMusc2: "glutes",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "The landmine press is a great alternative to a shoulder press !",
    calibKey: "landmineSquatPressDoubleArm",
    "AI Alternative Exercises":
      "barbellDoubleArmLandminePress,barbellLandmineSplitSquat,barbellBackSquat,barbellLunge,barbellLandminePress",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "72c10c7fcb",
  },
  latPulldown: {
    key: "latPulldown",
    name: "Lat Pull Down (Wide Bar)",
    altNames: "",
    plurName: "Lat Pull Down (Wide Bar)",
    shortName: "Lat Pull Down (Wide Bar)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 85,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "The Lat pull down is a compound exercise designed to target many muscles of the back, most notably the latissimus dorsi. This exercise can be completed using a lat pulldown bar, a D-handle bar, a chamption pro-style lat bar, or a V-bar",
    calibKey: "cableLatPulldownTraditional",
    "AI Alternative Exercises":
      "barbellMilitaryPress,vBarPulldown,barbellBentoverRow,dumbbellOverheadPress,seatedOverheadPressMachineHammerGrip",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 80,
    hash: "3897e79551",
  },
  vBarPulldown: {
    key: "vBarPulldown",
    name: "Lat Pull Down Narrow Hammer (V-Grip)",
    altNames: "Close",
    plurName: "Lat Pull Down Narrow Hammer (V-Grip)",
    shortName: "Lat Pull Down V-Grip",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "biceps",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableLatPulldownTraditional",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "14c96b5c70",
  },
  dumbbellLatRaise: {
    key: "dumbbellLatRaise",
    name: "Lat Raise",
    altNames: "",
    plurName: "Lat Raises",
    shortName: "Lat Raises",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "lats",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Lateral raises are an isolated movement that focus on the sides of your shoulders by building muscle and toning. They are completed with dumbbells.",
    calibKey: "dumbbellLateralRaiseTraditional",
    "AI Alternative Exercises":
      "dumbbellFrontRaise,dumbbellReverseFly,dumbbellSideRaiseToFrontRaise,cableSingleArmLateralRaise,cableFrontRaiseSingleArm",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 80,
    hash: "c133ce8c80",
  },
  latPulldownHammer: {
    key: "latPulldownHammer",
    name: "Lat Pull Down Wide Hammer",
    altNames: "",
    plurName: "Lat Pull Down Wide Hammer",
    shortName: "Lat Pull Down (Wide Hammer)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 85,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "The Lat pull down is a compound exercise designed to target many muscles of the back, most notably the latissimus dorsi. This exercise can be completed using a lat pulldown bar, a D-handle bar, a chamption pro-style lat bar, or a V-bar",
    calibKey: "cableLatPulldownWideHammer",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "59d4953fdd",
  },
  singlelegMachineLegCurl: {
    key: "singlelegMachineLegCurl",
    name: "Machine Leg Curl (Single Leg) ",
    altNames: "",
    plurName: "Machine Leg Curl (Single Leg) ",
    shortName: "Leg Curl (1-Leg)",
    primMusc1: "hamstrings",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLegCurlSeated",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "f9ee08421c",
  },
  machineLegCurlLying: {
    key: "machineLegCurlLying",
    name: "Leg Curl Machine Prone",
    altNames: "Hamstring",
    plurName: "Leg Curl Machine Prone",
    shortName: "Leg Curl Machine Prone",
    primMusc1: "hamstrings",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLegCurlLying",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "69ec81af5c",
  },
  legCurl: {
    key: "legCurl",
    name: "Leg / Hamstring Curl Seated",
    altNames: "Hamstring",
    plurName: "Leg / Hamstring Curls Seated",
    shortName: "Leg Curl Seated",
    primMusc1: "hamstrings",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLegCurlSeated",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "65660a28ea",
  },
  legExtension: {
    key: "legExtension",
    name: "Leg Extension",
    altNames: "",
    plurName: "Leg Extensions",
    shortName: "Leg Extension",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "hamstrings",
    secMusc2: "glutes",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "Leg extensions are a very effective leg and quadricep exercise. Using your legs extend upward against the padded machine to fully engage your quadriceps and leg muscles.",
    calibKey: "machineLegExtensionSeated",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "58842e7380",
  },
  singleLegMachineLegExtension: {
    key: "singleLegMachineLegExtension",
    name: "Leg Extension (Single Leg) ",
    altNames: "",
    plurName: "Leg Extension (Single Leg) ",
    shortName: "Leg Extension (1-Leg) ",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "hamstrings",
    secMusc2: "glutes",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLegExtensionSeated",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 30,
    hash: "a34bf96b62",
  },
  legPress: {
    key: "legPress",
    name: "Leg Press",
    altNames: "",
    plurName: "Leg Press",
    shortName: "Leg Press",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "glutes",
    secMusc2: "calves",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "Leg press is a very effective leg and quadricep exercise. Using your legs Push outward against the machine to fully engage your quadriceps and leg muscles.",
    calibKey: "machineLegPressAngled",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "12e71a5177",
  },
  machineLegPressMovingChair: {
    key: "machineLegPressMovingChair",
    name: "Leg Press Machine (Moving Chair)",
    altNames: "",
    plurName: "machineLegPressMovingChair",
    shortName: "Leg Press (Moving Chair)",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLegPressMovingChair",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "072ddfac1a",
  },
  singleLegLegPress: {
    key: "singleLegLegPress",
    name: "Leg Press (Single Leg)",
    altNames: "",
    plurName: "Leg Press (Single Leg)",
    shortName: "Leg Press (Single Leg)",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLegPressAngled",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "b30650d2ef",
  },
  legPressCalfRaise: {
    key: "legPressCalfRaise",
    name: "Leg Press Calf Raise",
    altNames: "",
    plurName: "legPressCalfRaise",
    shortName: "Leg Press Calf Raise",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "legPressCalfRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 70,
    hash: "a36ceec324",
  },
  captainsChairLegRaise: {
    key: "captainsChairLegRaise",
    name: "Captain's Chair Leg Raise",
    altNames: "",
    plurName: "Captain's Chair Leg Raise",
    shortName: "Leg Raise",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "machine",
    equip2: "bodyWeight",
    tip: "",
    desc: "",
    calibKey: "captainsChairLegRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "426e69b89b",
  },
  bodyweightLunge: {
    key: "bodyweightLunge",
    name: "Bodyweight Lunge",
    altNames: "",
    plurName: "Bodyweight Lunge",
    shortName: "Lunge",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "Place hands on your hips to track this exercise.",
    desc: "Lunges are an essential lower body exercise that help build stability, strength, and muscle. They focuses on your lower body, with an emphasis on the front of your legs.",
    calibKey: "bodyweightLungeTraditional",
    "AI Alternative Exercises":
      "bodyweightSquat,dumbbellLunge,dumbbellWalkingLunge,boxJump,dumbbellStepUp",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 50,
    hash: "d3ef380ae9",
  },
  legRaise: {
    key: "legRaise",
    name: "Lying Leg Raise",
    altNames: "",
    plurName: "Leg raises",
    shortName: "Lying Leg Raise",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Lie on your back, legs straight and together. Keep your legs straight and lift upwards. Reverse movement to complete exercise.",
    calibKey: "bodyweightLegRaiseTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "5c30c1c6a1",
  },
  machineGluteKickback: {
    key: "machineGluteKickback",
    name: "Machine Glute Kickback",
    altNames: "",
    plurName: "Machine Glute Kickbacks",
    shortName: "Mach. Glute Kickback",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineGluteKickback",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "0acb4c8e0b",
  },
  machineHipThrust: {
    key: "machineHipThrust",
    name: "Machine Hip Thrust (Glute Bridge)",
    altNames: "Drive",
    plurName: "Machine Hip Thrust (Glute Bridge)",
    shortName: "Mach. Hip Thrust",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineHipThrust",
    "AI Alternative Exercises": "",
    calCoef: 0.9,
    TVLFactor: 1,
    pop: 60,
    hash: "00107719b2",
  },
  machinePreacherCurlHammerGrip: {
    key: "machinePreacherCurlHammerGrip",
    name: "Machine Preacher Curl (Hammer Grip)",
    altNames: "",
    plurName: "Machine Preacher Curl (Hammer Grip)",
    shortName: "Mach. Preacher Curl (Hammer)",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machinePreacherCurlHammer",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 50,
    hash: "7ca3c49ffe",
  },
  machineTBarRowRegular: {
    key: "machineTBarRowRegular",
    name: "Machine T-Bar Row (Regular)",
    altNames: "",
    plurName: "Machine T-Bar Rows",
    shortName: "Mach. T-Bar Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineTBarRowTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.9,
    TVLFactor: 1,
    pop: 60,
    hash: "3dcde23d12",
  },
  machineTBarRow45DegreeGrip: {
    key: "machineTBarRow45DegreeGrip",
    name: "Machine T-Bar Row (45 Deg)",
    altNames: "",
    plurName: "Machine T-Bar Row (45 Deg.)",
    shortName: "Mach. T-Bar Row (45 Deg.)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineTBarRow45DegreeGrip",
    "AI Alternative Exercises": "",
    calCoef: 0.9,
    TVLFactor: 1,
    pop: 40,
    hash: "ab4aff6a24",
  },
  machineTBarRowHammerGrip: {
    key: "machineTBarRowHammerGrip",
    name: "Machine T-Bar Row (Hammer)",
    altNames: "",
    plurName: "Machine T-Bar Rows (Hammer)",
    shortName: "Mach. T-Bar Row (Hammer)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineTBarRowHammer",
    "AI Alternative Exercises": "",
    calCoef: 0.9,
    TVLFactor: 1,
    pop: 40,
    hash: "5bf45fdbac",
  },
  machineAlternateArmCurl: {
    key: "machineAlternateArmCurl",
    name: "Machine Alternate Arm Curl",
    altNames: "",
    plurName: "Machine Alternate Arm Curl",
    shortName: "Machine Alt. Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineBicepCurlAlternating",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "d4ad0fecec",
  },
  machineSeatedBackExtension: {
    key: "machineSeatedBackExtension",
    name: "Machine Seated Back Extension",
    altNames: "",
    plurName: "Machine Seated Back Extension",
    shortName: "Machine Back Ext.",
    primMusc1: "lowerBack",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineSeatedBackExtension",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "cd68cb36c2",
  },
  machineDeclineBenchPress: {
    key: "machineDeclineBenchPress",
    name: "Machine Decline Bench Press",
    altNames: "",
    plurName: "Machine Decline Bench Press",
    shortName: "Machine Decline Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineChestPressSeatedDeclineTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 50,
    hash: "8e1f870475",
  },
  pecDeck: {
    key: "pecDeck",
    name: "Machine Fly (Pec Dec)",
    altNames: "",
    plurName: "Pec Deck",
    shortName: "Machine Fly (Pec Dec)",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineFlyPecDeck",
    "AI Alternative Exercises": "cableRussianTwistLeft,cableRussianTwistRight",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 80,
    hash: "19411c05aa",
  },
  machineHighRow: {
    key: "machineHighRow",
    name: "Machine High Row (MTS Row)",
    altNames: "",
    plurName: "Machine High Rows",
    shortName: "Machine High Rows",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineHighRow",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "b9a05f89c9",
  },
  machineInclineBenchPress: {
    key: "machineInclineBenchPress",
    name: "Machine Incline Bench Press",
    altNames: "",
    plurName: "Machine Incline Bench Press",
    shortName: "Machine Incline Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineChestPressInclineTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 50,
    hash: "ea25e43c77",
  },
  machineLateralRaise: {
    key: "machineLateralRaise",
    name: "Machine Lateral Raise",
    altNames: "",
    plurName: "Machine Lateral Raise",
    shortName: "Machine Lat Raise",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "lats",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLateralRaiseTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "6c9e43fe35",
  },
  machinePreacherCurl: {
    key: "machinePreacherCurl",
    name: "Machine Preacher Curl",
    altNames: "",
    plurName: "Machine Preacher Curls",
    shortName: "Machine Preacher Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machinePreacherCurlTraditional",
    "AI Alternative Exercises":
      "cableRopeBicepCurl,machineTricepExtension,cableSupermanBicepCurl,ezBarPreacherCurl,cableBicepCurl",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "34caf6d3f8",
  },
  machineRearDeltFly: {
    key: "machineRearDeltFly",
    name: "Machine Rear Delt (Reverse) Fly",
    altNames: "",
    plurName: "Machine Rear Delt Fly",
    shortName: "Machine Rear Delt Fly",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineRearDeltFly",
    "AI Alternative Exercises":
      "cableUprightRearDeltFly,machineRow,pecDeck,machineRowHammerGrip",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 80,
    hash: "38c2d0ad1c",
  },
  machineRow: {
    key: "machineRow",
    name: "Machine Row",
    altNames: "",
    plurName: "Machine Row",
    shortName: "Machine Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineRowTraditional",
    "AI Alternative Exercises":
      "cableSeatedRow,machineChestPressSeatedHammer,machineRearDeltFly,machineRowHammerGrip",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "0df312abfa",
  },
  machineRowHammerGrip: {
    key: "machineRowHammerGrip",
    name: "Machine Row (Hammer Grip)",
    altNames: "",
    plurName: "Machine Row (Hammer Grip)",
    shortName: "Machine Row (Hammer)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineRowHammer",
    "AI Alternative Exercises":
      "vBarSeatedCableRow,machineChestPressSeatedHammer",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "4997c3f434",
  },
  seatedCalfRaiseMachine: {
    key: "seatedCalfRaiseMachine",
    name: "Machine Seated Calf Raise",
    altNames: "Extension",
    plurName: "Machine Seated Calf Raise",
    shortName: "Machine Seated Calf Raise",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineCalfRaiseSeated",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "8fb383a165",
  },
  machineShoulderShrug: {
    key: "machineShoulderShrug",
    name: "Machine Shrug",
    altNames: "",
    plurName: "Machine Shrugs",
    shortName: "Machine Shrug",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineShoulderShrug",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 60,
    hash: "c66fadbe40",
  },
  machineStandingCalfRaise: {
    key: "machineStandingCalfRaise",
    name: "Machine Standing Calf Raise",
    altNames: "Extension",
    plurName: "Machine Standing Calf Raises",
    shortName: "Machine Standing Calf Raise",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "Stand on a flat surface with your toes pointed straight ahead. Lift your heels off the floor to flex your calf muscle. Pause for a moment, then slowly return to the floor",
    calibKey: "machineCalfRaiseStanding",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "3faf6b383b",
  },
  machineTricepExtension: {
    key: "machineTricepExtension",
    name: "Machine Tricep Extension",
    altNames: "",
    plurName: "Machine Tricep Extension",
    shortName: "Machine Tri Ext.",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineTricepExtensionTraditional",
    "AI Alternative Exercises":
      "dumbbellHammerCurl,cableRopeOverheadTriExtensionHigh,sitUp",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "d0cfbebe5a",
  },
  machineTricepPress: {
    key: "machineTricepPress",
    name: "Machine Tricep Press",
    altNames: "Seated Dip",
    plurName: "Machine Tricep Press",
    shortName: "Machine Tri Press",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineTricepPressTraditional",
    "AI Alternative Exercises": "barbellLandmineSplitSquat",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "17cb3ec8f3",
  },
  barbellMilitaryPress: {
    key: "barbellMilitaryPress",
    name: "Overhead Press / Military Press",
    altNames: "Barbell Overhead",
    plurName: "Overhead Press / Military Press",
    shortName: "Overhead Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Military Press is an essential upper body exercise that helps build strength and stability primarily in your deltoids, rear deltoids, and triceps. This can be completed with dumbbells, kettlebells, or with a barbell. ",
    calibKey: "barbellMilitaryPressTraditional",
    "AI Alternative Exercises":
      "barbellInclineBenchPress,barbellCloseGripBenchPress,barbellBenchPress,seatedOverheadPressMachineRegularGrip",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "038b3eb8b1",
  },
  behindHeadMilitaryPress: {
    key: "behindHeadMilitaryPress",
    name: "Overhead Press / Military Press (Behind Head)",
    altNames: "Barbell Overhead",
    plurName: "Overhead Press / Military Press (Behind Head)",
    shortName: "Overhead Press (Behind Head)",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellMilitaryPressBehindHead",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "624a75d61d",
  },
  mountainClimber: {
    key: "mountainClimber",
    name: "Mountain Climber",
    altNames: "",
    plurName: "Mountain Climbers",
    shortName: "Mountain Climbers",
    primMusc1: "abs",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Mountain climber is a bodyweight exercise that serves as a strong full body workout, primarily increasing core stength and providing definition in the shoulders, chest, and upper back. ",
    calibKey: "bodyweightMountainClimberTraditional",
    "AI Alternative Exercises": "benchDips,pushUp",
    calCoef: 0.2,
    TVLFactor: 1,
    pop: 40,
    hash: "bb031c09e2",
  },
  barbellMuscleClean: {
    key: "barbellMuscleClean",
    name: "Muscle Clean",
    altNames: "",
    plurName: "Muscle Clean",
    shortName: "Muscle Clean",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellMuscleClean",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "fe110422e6",
  },
  barbellMuscleSnatch: {
    key: "barbellMuscleSnatch",
    name: "Muscle Snatch",
    altNames: "",
    plurName: "Muscle Snatch",
    shortName: "Muscle Snatch",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellMuscleSnatch",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "64d1b212f1",
  },
  muscleUp: {
    key: "muscleUp",
    name: "Muscle Up",
    altNames: "",
    plurName: "Muscle Up",
    shortName: "Muscle Up",
    primMusc1: "lats",
    primMusc2: "shoulders",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightMuscleUp",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "c702af94e2",
  },
  nordicHamstringCurl: {
    key: "nordicHamstringCurl",
    name: "Nordic Hamstring Curl",
    altNames: "",
    plurName: "Nordic Hamstring Curl",
    shortName: "Nordic Hamstring Curl",
    primMusc1: "hamstrings",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "nordicHamstringCurl",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 10,
    hash: "32681240cc",
  },
  obliqueVUps: {
    key: "obliqueVUps",
    name: "Oblique V-Ups",
    altNames: "",
    plurName: "Oblique V-Ups",
    shortName: "Oblique V-Ups",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightObliqueVUps",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 20,
    hash: "508553c012",
  },
  pikePushUp: {
    key: "pikePushUp",
    name: "Pike Push Up",
    altNames: "",
    plurName: "Pike Push Up",
    shortName: "Pike Push Up",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPushUpPike",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "aa94de83d3",
  },
  bodyweightPistolSquats: {
    key: "bodyweightPistolSquats",
    name: "Pistol Squats",
    altNames: "",
    plurName: "Pistol Squats",
    shortName: "Pistol Squats",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPistolSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 10,
    hash: "484eb5d655",
  },
  plank: {
    key: "plank",
    name: "Plank",
    altNames: "",
    plurName: "Plank",
    shortName: "Plank",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "obliques",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "The plank is an isometric core strength exercise that involves maintaining a position similar to a push-up for the maximum possible time",
    calibKey: "bodyweightPlankTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.2,
    TVLFactor: 1,
    pop: 40,
    hash: "c80bf876dd",
  },
  plankJack: {
    key: "plankJack",
    name: "Plank Jack",
    altNames: "",
    plurName: "Plank Jacks",
    shortName: "Plank Jack",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPlankJackHands",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "4da432c6bb",
  },
  plankTap: {
    key: "plankTap",
    name: "Plank Tap",
    altNames: "",
    plurName: "Plank Taps",
    shortName: "Plank Taps",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Plank Taps are an advanced variation on the classic plank position that work to  strengthen core, shoulders, and chest. No equipment is required to complete this exercise.",
    calibKey: "bodyweightPlankTaps",
    "AI Alternative Exercises": "",
    calCoef: 0.2,
    TVLFactor: 1,
    pop: 40,
    hash: "15c995926e",
  },
  plankUpDown: {
    key: "plankUpDown",
    name: "Plank Up Down",
    altNames: "",
    plurName: "Plank Up Downs",
    shortName: "Plank Up Down",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "obliques",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "The plank is an isometric core strength exercise that involves maintaining a position similar to a push-up for the maximum possible time. In this variation, while in the plank position, one arm at a time shift from a 90 degree angle to a fully extended arm pushing away from the ground. Reverse movement to complete exercise.",
    calibKey: "bodyweightPlankUpDown",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "be1133cab1",
  },
  plateFrontRaise: {
    key: "plateFrontRaise",
    name: "Plate Front Raise",
    altNames: "",
    plurName: "Plate Front Raises",
    shortName: "Plate Front Raises",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "traps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "Front raises are an isolated movement that focus on the front of your shoulders. They can be completed with dumbbells, cables, or plates. ",
    calibKey: "plateFrontRaise",
    "AI Alternative Exercises":
      "dumbbellFrontRaise,cableCrunch,machineTricepExtension",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "38a652ab9c",
  },
  barbellPowerClean: {
    key: "barbellPowerClean",
    name: "Power Clean",
    altNames: "",
    plurName: "Power Clean",
    shortName: "Power Clean",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellPowerClean",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "a98a202f49",
  },
  barbellPowerCleanPress: {
    key: "barbellPowerCleanPress",
    name: "Power Clean and Press",
    altNames: "",
    plurName: "Power Clean & Press",
    shortName: "Power Clean and Press",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellPowerCleanAndPress",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "a37353b3fd",
  },
  barbellPowerCleanThruster: {
    key: "barbellPowerCleanThruster",
    name: "Power Clean Thruster",
    altNames: "",
    plurName: "Power Clean Thruster",
    shortName: "Power Clean Thruster",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellPowerCleanTruster",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "068236bbc5",
  },
  powerSnatch: {
    key: "powerSnatch",
    name: "Power Snatch",
    altNames: "",
    plurName: "Power Snatches",
    shortName: "Power Snatch",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Drive barbell up with legs, once bar passes knees, explosively extend hips and use momentum to carry bar up. Once hips are fully extended, quickly drive body under and catch the barbell in the overhead, with elbows as high as possible and in a partial quarter squat.",
    calibKey: "barbellPowerSnatch",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "43fbc420b1",
  },
  pullUp: {
    key: "pullUp",
    name: "Pull Up",
    altNames: "",
    plurName: "Pull Ups",
    shortName: "Pull Ups",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "other",
    tip: "",
    desc: "A Pull up is an essential upper body exercise that focuses primarily on strengthening various muscles on your back. To perform a Pull up you must grip an overhead bar and lift your body until your chin is over the bar.",
    calibKey: "bodyweightPullUpTraditional",
    "AI Alternative Exercises": "hammerPullUp,chinUp,dips",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 90,
    hash: "f0d6e6aebf",
  },
  pushUpOnKnees: {
    key: "pushUpOnKnees",
    name: "Push Up on Knees",
    altNames: "",
    plurName: "Push Up on Knees",
    shortName: "Push Up (Knees)",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPushUpOnKnees",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "cb9402552f",
  },
  pushUp: {
    key: "pushUp",
    name: "Push Up",
    altNames: "",
    plurName: "Push Ups",
    shortName: "Push Ups",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Push ups are an essential upper body exercise that engage your entire upper body, with an emphasis on your chest and triceps. ",
    calibKey: "bodyweightPushUpTraditional",
    "AI Alternative Exercises": "benchDips,diamondPushUp,dips,mountainClimber",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 90,
    hash: "965e675fe4",
  },
  barbellRackPull: {
    key: "barbellRackPull",
    name: "Rack Pull",
    altNames: "Block",
    plurName: "Rack Pulls",
    shortName: "Rack Pulls",
    primMusc1: "lowerBack",
    primMusc2: "lats",
    secMusc1: "traps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Rack pulls are a variation of the traditional deadlift, with a shorter range of motion of pulling only from knee height rather than from the ground. Deadlifts are an essential exercise that help build both strength, and muscle. They can be completed with dumbbells, a barbell, a trap bar or cables. The exercise focuses on your whole body, with an emphasis on your lower back.  ",
    calibKey: "barbellRackPull",
    "AI Alternative Exercises": "barbellDeadlift,trapBarDeadlift,barbellShrug",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "7dd33ac149",
  },
  dumbbellRenegadeRow: {
    key: "dumbbellRenegadeRow",
    name: "Dumbbell Renegade Row",
    altNames: "Plank",
    plurName: "Dumbbell Renegade Rows",
    shortName: "Renegade Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellRenegadeRow",
    "AI Alternative Exercises": "mountainClimber,dumbbellRow",
    calCoef: 0.6,
    TVLFactor: 2,
    pop: 30,
    hash: "7e516e6a30",
  },
  dumbbellRenegadeRowPushUp: {
    key: "dumbbellRenegadeRowPushUp",
    name: "Dumbbell Renegade Row W/ Push Up",
    altNames: "Plank",
    plurName: "Dumbbell Renegade Rows W/ PushUps",
    shortName: "Renegade Row PushUp",
    primMusc1: "lats",
    primMusc2: "chest",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellRenegadeRowPushUp",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 30,
    hash: "2bbe0fd854",
  },
  resistanceBandRow: {
    key: "resistanceBandRow",
    name: "Resistance Band Row",
    altNames: "",
    plurName: "Resistance Band Row",
    shortName: "Resistance Band Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandRowTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "bc40bfd97b",
  },
  reverseCrunch: {
    key: "reverseCrunch",
    name: "Reverse Crunch",
    altNames: "",
    plurName: "Reverse Crunches",
    shortName: "Reverse Crunch",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightReverseCrunch",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "062e65bc5c",
  },
  reversePlank: {
    key: "reversePlank",
    name: "Reverse Plank",
    altNames: "",
    plurName: "Reverse Plank",
    shortName: "Reverse Plank",
    primMusc1: "abs",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPlankReverseHands",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "17548d01c8",
  },
  reverseElbowPlank: {
    key: "reverseElbowPlank",
    name: "Reverse Plank Elbows",
    altNames: "",
    plurName: "Reverse Plank Elbows",
    shortName: "Reverse Plank Elbows",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPlankElbows",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "638f2c87df",
  },
  ringDips: {
    key: "ringDips",
    name: "Ring Dip",
    altNames: "",
    plurName: "Ring Dip",
    shortName: "Ring Dips",
    primMusc1: "chest",
    primMusc2: "triceps",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "other",
    equip2: "bodyWeight",
    tip: "",
    desc: "",
    calibKey: "ringDips",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "8ab361481c",
  },
  ringPullUps: {
    key: "ringPullUps",
    name: "Ring Pull Up",
    altNames: "",
    plurName: "Ring Pull Up",
    shortName: "Ring Pull Ups",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "ringPullUps",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "8bfde8a0d0",
  },
  barbellRomanianDeadlift: {
    key: "barbellRomanianDeadlift",
    name: "Romanian Deadlift",
    altNames: "RDL",
    plurName: "Romanian Deadlift",
    shortName: "Romanian Deadlift (RDL)",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "lowerBack",
    secMusc2: "quads",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellRomanianDeadlift",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "c9dd20f5ba",
  },
  cableRopeBicepCurl: {
    key: "cableRopeBicepCurl",
    name: "Cable Bicep Curl (Rope)",
    altNames: "",
    plurName: "Cable Bicep Curl (Rope)",
    shortName: "Rope Bicep Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Bicep curls are an isolated movement that can be completed with a barbell, dumbbells, cables, or resistance bands. They focus on the front of your arm. ",
    calibKey: "cableBicepCurlRope",
    "AI Alternative Exercises":
      "cableBicepCurl,resistanceBandBicepCurl,ezBarBicepCurl,dumbbellHammerCurl",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "c8fa23ca8f",
  },
  machineRopePull: {
    key: "machineRopePull",
    name: "Rope Pull Machine",
    altNames: "",
    plurName: "Rope Pull Machine",
    shortName: "Rope Pull Machine",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "distance",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineRopePull",
    "AI Alternative Exercises": "",
    calCoef: 0.2,
    TVLFactor: 1,
    pop: 40,
    hash: "1021174b2c",
  },
  ergRowingMachine: {
    key: "ergRowingMachine",
    name: "Rowing Machine",
    altNames: "Erg Machine",
    plurName: "Rowing Machine",
    shortName: "Rowing Machine",
    primMusc1: "lats",
    primMusc2: "lowerBack",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "distance",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "ergRowingMachine",
    "AI Alternative Exercises": "",
    calCoef: 0.1,
    TVLFactor: 1,
    pop: 40,
    hash: "89524e7a5f",
  },
  russianTwist: {
    key: "russianTwist",
    name: "Russian Twist",
    altNames: "",
    plurName: "Russian Twist",
    shortName: "Russian Twist",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Russian twists are an essential core exercise that can be completed with or without weights. They focus on the sides of your core, also known as your obliques.",
    calibKey: "dumbbellRussianTwist",
    "AI Alternative Exercises":
      "cableRopeOverheadTriExtensionLow,barbellUprightRow",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 60,
    hash: "262da0a532",
  },
  cableReverseGripBarCurl: {
    key: "cableReverseGripBarCurl",
    name: "Reverse Grip Cable Curl Bar",
    altNames: "",
    plurName: "Reverse Grip Cable Curl Bar",
    shortName: "Rvs Grip Cable Curl (Bar)",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableBicepCurlBarReverseGrip",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 40,
    hash: "63d0a288b0",
  },
  barbellReverseGripCurl: {
    key: "barbellReverseGripCurl",
    name: "Reverse Grip Barbell Curl",
    altNames: "",
    plurName: "Reverse Grip Barbell Curls",
    shortName: "Rvs Grip Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "forearms",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellBicepCurlReverseGrip",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 30,
    hash: "1da35e4efb",
  },
  barbellReverseGripBentoverRow: {
    key: "barbellReverseGripBentoverRow",
    name: "Reverse Grip Bent Over Row",
    altNames: "",
    plurName: "Reverse Grip Bent Over Row",
    shortName: "Rvs Grip Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "Reverse Grip Bent over rows are an essential upper body exercise that focus on your lats and biceps. They can be completed with a barbell, dumbbells, cables, or resistance bands. The exercise allows you to build both muscle and strength. To perform a reverse grip row you must place your hands on the equipment with palms facing away from your body.",
    calibKey: "barbellBentoverRowReverseGrip",
    "AI Alternative Exercises":
      "seatedOverheadPressMachineRegularGrip,barbellDeadlift,barbellRackPull,tBarRow",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "18a13ca71c",
  },
  reverseGripDumbbellCurl: {
    key: "reverseGripDumbbellCurl",
    name: "Reverse Grip Dumbbell Bicep Curl",
    altNames: "",
    plurName: "Reverse Grip Dumbbell Bicep Curl",
    shortName: "Rvs. Grip Bicep Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellBicepCurlReverse",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "c42f21fb83",
  },
  reverseGripLatPulldown: {
    key: "reverseGripLatPulldown",
    name: "Reverse Grip Pull Down",
    altNames: "Underhand",
    plurName: "Reverse Grip Pull Down",
    shortName: "Rvse Grip Pull Down",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 85,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "The Lat pull down is a compound exercise designed to target many muscles of the back, most notably the latissimus dorsi. This exercise can be completed using a lat pulldown bar, a D-handle bar, a chamption pro-style lat bar, or a V-bar. To use reverse grip you must put your hands on the bar with palms facing towards your body.",
    calibKey: "cableLatPulldownReverse",
    "AI Alternative Exercises":
      "barbellReverseGripBentoverRow,dumbbellRenegadeRow,barbellDeadlift,barbellFrontSquat,latPulldown",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "ba7139d43e",
  },
  scissorKick: {
    key: "scissorKick",
    name: "Scissor Kick",
    altNames: "",
    plurName: "Scissor Kicks",
    shortName: "Scissor Kick",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Similar to flutter kicks,scissor kicks are an exercise that works the muscles of your core, specifically the lower rectus abdominal muscles, plus the hip flexors.",
    calibKey: "bodyweightScissorKick",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "0ebc914461",
  },
  seatedBarbellShoulderPress: {
    key: "seatedBarbellShoulderPress",
    name: "Seated Barbell Shoulder Press",
    altNames: "",
    plurName: "Seated Barbell Shoulder Press",
    shortName: "Seated BB Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellShoulderPressSeated",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "e92235a40f",
  },
  cableSeatedRow: {
    key: "cableSeatedRow",
    name: "Seated Cable Row (Bar)",
    altNames: "Seated Machine,Back Lat",
    plurName: "Seated Cable Row (Bar)",
    shortName: "Seated Cable Row (Bar)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 40,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "The Cable row is an essential exercise to strengthen upper body, primarily targeting the muscles in the back and biceps. To perform you can use various types of cable bars. ",
    calibKey: "cableSeatedRowBarTraditional",
    "AI Alternative Exercises": "machineRow,vBarSeatedCableRow",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "911f003a30",
  },
  vBarSeatedCableRow: {
    key: "vBarSeatedCableRow",
    name: "Seated Cable Row (V Handle)",
    altNames: "",
    plurName: "Seated Cable Row (V Handle)",
    shortName: "Seated Cable Row (V Handle)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "The Cable row is an essential exercise to strengthen upper body, primarily targeting the muscles in the back and biceps. To perform you can use various types of cable bars. ",
    calibKey: "cableSeatedRowDBar",
    "AI Alternative Exercises": "machineRowHammerGrip,tBarRow",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "08935d6822",
  },
  cableSeatedRowWide: {
    key: "cableSeatedRowWide",
    name: "Seated Cable Row (Wide Grip)",
    altNames: "Seated Machine,Back Lat",
    plurName: "Seated Cable Row (Wide Grip)",
    shortName: "Seated Row (Wide)",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 45,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "The Cable row is an essential exercise to strengthen upper body, primarily targeting the muscles in the back and biceps. To perform you can use various types of cable bars. ",
    calibKey: "cableSeatedRowBarWide",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "0219074a41",
  },
  dumbbellSeatedShrug: {
    key: "dumbbellSeatedShrug",
    name: "Dumbbell Seated Shrug",
    altNames: "",
    plurName: "Dumbbell Seated Shrugs",
    shortName: "Seated Shrug",
    primMusc1: "traps",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellShrugSeated",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 30,
    hash: "36cc6dce8c",
  },
  shotgunRow: {
    key: "shotgunRow",
    name: "Shotgun Row (Cable)",
    altNames: "",
    plurName: "Shotgun Rows",
    shortName: "Shotgun Row ",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellRowShotgun",
    "AI Alternative Exercises": "",
    calCoef: 0.9,
    TVLFactor: 2,
    pop: 30,
    hash: "9eff107f8b",
  },
  seatedOverheadPressMachineRegularGrip: {
    key: "seatedOverheadPressMachineRegularGrip",
    name: "Shoulder Press Machine (Regular)",
    altNames: "Military, Overhead",
    plurName: "Shoulder Press Machine",
    shortName: "Shoulder Press Mach.",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineShoulderPressTraditional",
    "AI Alternative Exercises":
      "seatedOverheadPressMachineHammerGrip,dumbbellOverheadPress,barbellMilitaryPress",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 70,
    hash: "acd29d0b82",
  },
  seatedOverheadPressMachineHammerGrip: {
    key: "seatedOverheadPressMachineHammerGrip",
    name: "Shoulder Press Machine (Hammer)",
    altNames: "Military, Overhead",
    plurName: "Shoulder Press Machine (Hammer)",
    shortName: "Shoulder Press Mach. (Hammer)",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineShoulderPressHammer",
    "AI Alternative Exercises":
      "dumbbellOverheadPress,seatedOverheadPressMachineRegularGrip,barbellMilitaryPress,latPulldown,dumbbellInclineBenchPress",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 60,
    hash: "c25d303ff6",
  },
  sideBend: {
    key: "sideBend",
    name: "Side Bend Weighted",
    altNames: "",
    plurName: "Side Bend Weighted",
    shortName: "Side Bend Weighted",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "plateSideBend",
    "AI Alternative Exercises":
      "barbellRackPull,barbellBulgarianSplit,barbellDeadlift,dumbbellDeadlift,dumbbellBulgarianSplitSquat",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "c58e2b0e16",
  },
  sidePlank: {
    key: "sidePlank",
    name: "Side Plank",
    altNames: "",
    plurName: "Side Plank",
    shortName: "Side Plank",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPlankSideLeft",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 40,
    hash: "f7ef702c2b",
  },
  bodyweightSidePlankLeft: {
    key: "bodyweightSidePlankLeft",
    name: "Side Plank (Left)",
    altNames: "",
    plurName: "bodyweightSidePlankLeft",
    shortName: "Side Plank (Left)",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightSidePlankLeft",
    "AI Alternative Exercises": "",
    calCoef: 0.2,
    TVLFactor: 1,
    pop: 40,
    hash: "2174ca0543",
  },
  bodyweightSidePlankRight: {
    key: "bodyweightSidePlankRight",
    name: "Side Plank (Right)",
    altNames: "",
    plurName: "bodyweightSidePlankRight",
    shortName: "Side Plank (Right)",
    primMusc1: "obliques",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightSidePlankRight",
    "AI Alternative Exercises": "",
    calCoef: 0.2,
    TVLFactor: 1,
    pop: 40,
    hash: "ef9a839629",
  },
  dumbbellSideRaiseToFrontRaise: {
    key: "dumbbellSideRaiseToFrontRaise",
    name: "Side Raise to Front Raise",
    altNames: "",
    plurName: "Side Raise to Front Raise",
    shortName: "Side to Front Raise",
    primMusc1: "shoulders",
    primMusc2: "lats",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellSideRaiseToFrontRaise",
    "AI Alternative Exercises":
      "plateFrontRaise,cableSingleArmLateralRaise,dumbbellFrontRaise,dumbbellLatRaise",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 50,
    hash: "e7c89fc845",
  },
  singleArmLatPulldown: {
    key: "singleArmLatPulldown",
    name: "Single Arm Lat Pulldown",
    altNames: "",
    plurName: "Single Arm Lat Pulldown",
    shortName: "Single Arm Lat Pulldown",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "traps",
    exCat: "reps",
    weight: 40,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "singleArmLatPulldown",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "6a9bcbcc2b",
  },
  dumbbellBentOverSingleArmRow: {
    key: "dumbbellBentOverSingleArmRow",
    name: "Bent Over Single Arm Row",
    altNames: "",
    plurName: "Bent Over Single Arm Rows",
    shortName: "Single Arm Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Place one knee and one hand (on the same side of the body) on a bench with your back straight and parallel to the floor. Using your other hand , bring a dumbbel to your chest and then fully extend back down.",
    calibKey: "dumbbellRowBentOverSingleArm",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 30,
    hash: "afb3257add",
  },
  bodyweightSingleLegHipThruster: {
    key: "bodyweightSingleLegHipThruster",
    name: "Single Leg Hip Thruster",
    altNames: "",
    plurName: "Single Leg Hip Thrusters",
    shortName: "Single Leg Hip Thruster",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightSingleLegHipThruster",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 40,
    hash: "63405c0b17",
  },
  sitUp: {
    key: "sitUp",
    name: "Sit Up",
    altNames: "",
    plurName: "Sit Ups",
    shortName: "Sit Ups",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "Place your hands on your head, touching your ears for best tracking results.",
    desc: "Sit ups are an essential exercise that help build core strength and tone. They focus on the front of your core, primarily your abs.",
    calibKey: "bodyweightSitUpTraditional",
    "AI Alternative Exercises": "crunch,jackknifeSitUp,toeTouches",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 70,
    hash: "ec86e2b46a",
  },
  sledPush: {
    key: "sledPush",
    name: "Sled Push",
    altNames: "",
    plurName: "Sled Pushes",
    shortName: "Sled Push",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "calves",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "Stand behind the sled and grab the poles with a high-grip hand position. Engage your core muscles and start pushing the sled forward as fast as you can, powering through your entire leg. Extend your hips and knees as you move the sled forward. Your foot stance should resemble your natural running position",
    calibKey: "sledPush",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 30,
    hash: "4c734dcd65",
  },
  smithMachineBehindBackShrug: {
    key: "smithMachineBehindBackShrug",
    name: "Smith Machine Behind Back Shrug",
    altNames: "",
    plurName: "Smith Machine Behind Back Shrug",
    shortName: "Smith M. Behind Back Shrug",
    primMusc1: "traps",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineShrugBehindBack",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 20,
    hash: "0659f9dad2",
  },
  smithMachineBenchPress: {
    key: "smithMachineBenchPress",
    name: "Smith Machine Bench Press",
    altNames: "",
    plurName: "Smith Machine Bench Press",
    shortName: "Smith M. Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineBenchPressTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "a80bb40e12",
  },
  smithMachineBentoverRow: {
    key: "smithMachineBentoverRow",
    name: "Smith Machine Bent Over Row",
    altNames: "",
    plurName: "Smith Machine Bentover Row",
    shortName: "Smith M. Bentover Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineBentoverRow",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "a1f0c2c8ab",
  },
  smithMachineCalfRaise: {
    key: "smithMachineCalfRaise",
    name: "Smith Machine Calf Raise",
    altNames: "Extension",
    plurName: "Smith Machine Calf Raise",
    shortName: "Smith M. Calf Raise",
    primMusc1: "calves",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineCalfRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "e24404d742",
  },
  smithMachineDeadlift: {
    key: "smithMachineDeadlift",
    name: "Smith Machine Deadlift",
    altNames: "",
    plurName: "Smith Machine Deadlift",
    shortName: "Smith M. Deadlift",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineDeadliftTraditional",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "f337efb73c",
  },
  smithMachineInclineBenchPress: {
    key: "smithMachineInclineBenchPress",
    name: "Smith Machine Incline Bench Press",
    altNames: "",
    plurName: "Smith Machine Incline Bench Press",
    shortName: "Smith M. Incline Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineBenchPressInclineTraditional",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "4f06912d92",
  },
  smithMachineShoulderPress: {
    key: "smithMachineShoulderPress",
    name: "Smith Machine Shoulder Press",
    altNames: "Military, Overhead",
    plurName: "Smith Machine Shoulder Press",
    shortName: "Smith M. Shoulder Press",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 95,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineShoulderPressStanding",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "7fac854a15",
  },
  smithMachineShrug: {
    key: "smithMachineShrug",
    name: "Smith Machine Shrug",
    altNames: "",
    plurName: "Smith Machine Shrug",
    shortName: "Smith M. Shrug",
    primMusc1: "traps",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineShrugTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "221ad15860",
  },
  smithMachineSplitSquat: {
    key: "smithMachineSplitSquat",
    name: "Smith Machine Split Squat",
    altNames: "",
    plurName: "Smith Machine Split Squats",
    shortName: "Smith M. Split Squat",
    primMusc1: "glutes",
    primMusc2: "hamstrings",
    secMusc1: "quads",
    secMusc2: "",
    exCat: "reps",
    weight: 95,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineSplitSquat",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "0dcc4c9e9e",
  },
  smithMachineSquat: {
    key: "smithMachineSquat",
    name: "Smith Machine Squat",
    altNames: "",
    plurName: "Smith Machine Squats",
    shortName: "Smith M. Squat",
    primMusc1: "hamstrings",
    primMusc2: "quads",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineBackSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 70,
    hash: "09c70da930",
  },
  smithMachineUprightRow: {
    key: "smithMachineUprightRow",
    name: "Smith Machine Upright Row",
    altNames: "",
    plurName: "Smith Machine Upright Rows",
    shortName: "Smith M. Upright Row",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "lats",
    secMusc2: "",
    exCat: "reps",
    weight: 55,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineUprightRow",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "25bcc5595a",
  },
  smithMachineRomanianDeadlift: {
    key: "smithMachineRomanianDeadlift",
    name: "Smith Machine Romanian Deadlift",
    altNames: "RDL",
    plurName: "Smith Machine Romanian Deadlift",
    shortName: "Smith Machine RDL",
    primMusc1: "hamstrings",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineRomanianDeadlift",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 50,
    hash: "3f8cded94e",
  },
  barbellSnatch: {
    key: "barbellSnatch",
    name: "Snatch (Barbell)",
    altNames: "",
    plurName: "Snatch (Barbell)",
    shortName: "Snatch (Barbell)",
    primMusc1: "glutes",
    primMusc2: "quads",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellSnatchTraditional",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 20,
    hash: "92da130094",
  },
  spiderCurls: {
    key: "spiderCurls",
    name: "Spider Curls",
    altNames: "",
    plurName: "Spider Curls",
    shortName: "Spider Curls",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "bench",
    tip: "",
    desc: "",
    calibKey: "dumbbellBicepCurlSpiderman",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 50,
    hash: "50fb22e7ed",
  },
  spidermanPushUp: {
    key: "spidermanPushUp",
    name: "Spiderman Push Up",
    altNames: "",
    plurName: "Spiderman Push Ups",
    shortName: "Spiderman Push Up",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "obliques",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPushUpSpiderman",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "72c605687c",
  },
  stationarySpinning: {
    key: "stationarySpinning",
    name: "Spinning / Stationary Biking",
    altNames: "",
    plurName: "stationarySpinning",
    shortName: "Spinning",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "distance",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "stationarySpinning",
    "AI Alternative Exercises": "",
    calCoef: 0.1,
    TVLFactor: 1,
    pop: 70,
    hash: "f9f6cb7874",
  },
  stairClimber: {
    key: "stairClimber",
    name: "Stair Climber",
    altNames: "Master, Stepper",
    plurName: "stairClimber",
    shortName: "Stair Climbers",
    primMusc1: "quads",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "distance",
    weight: 0,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "stairClimber",
    "AI Alternative Exercises": "",
    calCoef: 0.1,
    TVLFactor: 1,
    pop: 70,
    hash: "c2bf967344",
  },
  cableStraightArmPulldown: {
    key: "cableStraightArmPulldown",
    name: "Straight Arm Cable Pull Down (Bar)",
    altNames: "Stiff Arm",
    plurName: "Straight Arm Cable Pull Down (Bar)",
    shortName: "Straight Arm Pulldown (Bar)",
    primMusc1: "lowerBack",
    primMusc2: "lats",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 35,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableStraightArmPulldownBar",
    "AI Alternative Exercises":
      "cableTricepExtension,dumbbellLatRaise,cableRopeStraightArmPulldown,cableTricepExtensionVBar,cableFrontRaiseSingleArm",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "5b59475aea",
  },
  cableRopeStraightArmPulldown: {
    key: "cableRopeStraightArmPulldown",
    name: "Straight Arm Cable Pull Down (Rope)",
    altNames: "Stiff Arm",
    plurName: "Straight Arm Cable Pull Down (Rope)",
    shortName: "Straight Arm Pulldown (Rope)",
    primMusc1: "lowerBack",
    primMusc2: "lats",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 35,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableStraightArmPulldownRope",
    "AI Alternative Exercises":
      "cableRopeTricepExtension,cableStraightArmPulldown,cableTricepExtensionVBar,plateFrontRaise",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 50,
    hash: "c25f500105",
  },
  barbellSumoDeadlift: {
    key: "barbellSumoDeadlift",
    name: "Sumo Deadlift",
    altNames: "",
    plurName: "Sumo Deadlift",
    shortName: "Sumo Deadlift",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellDeadliftSumoTraditional",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 80,
    hash: "35d0045930",
  },
  svendPress: {
    key: "svendPress",
    name: "Svend Press (Plate Push)",
    altNames: "Pinch",
    plurName: "Svend Press",
    shortName: "Svend Press",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "plateSvendPress",
    "AI Alternative Exercises":
      "barbellDoubleArmLandminePress,landmineSquatPressDoubleArm",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 30,
    hash: "23dc8f5e09",
  },
  pilatesSwimmers: {
    key: "pilatesSwimmers",
    name: "Pilates Swimmer",
    altNames: "",
    plurName: "Pilates Swimmer",
    shortName: "Swimmers",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightPilatesSwimmers",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 20,
    hash: "983b94ac40",
  },
  tireFlip: {
    key: "tireFlip",
    name: "Tire Flip",
    altNames: "",
    plurName: "Tire Flips",
    shortName: "Tire Flip",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "lats",
    secMusc2: "lowerBack",
    exCat: "reps",
    weight: 100,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "tireFlip",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 1,
    pop: 40,
    hash: "a7126bfa46",
  },
  toeTouches: {
    key: "toeTouches",
    name: "Toe Touches",
    altNames: "",
    plurName: "Toe Touches",
    shortName: "Toe Touches",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Toe touches are a great core exercise that can be used towards the end of your core workout. They focus on the sides of your core, also known as your obliques.",
    calibKey: "bodyweightToeTouches",
    "AI Alternative Exercises":
      "jackknifeSitUp,deadBug,cableFacePull,abRollOut,russianTwist",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 50,
    hash: "54e40e608f",
  },
  toesToBar: {
    key: "toesToBar",
    name: "Toes to Bar",
    altNames: "",
    plurName: "Toes to bar",
    shortName: "Toes to Bar",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "other",
    equip2: "bodyWeight",
    tip: "",
    desc: "",
    calibKey: "bodyweightToesToBar",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 20,
    hash: "839306d70f",
  },
  trapBarDeadlift: {
    key: "trapBarDeadlift",
    name: "Trap Bar Deadlift",
    altNames: "",
    plurName: "Trap Bar Deadlift",
    shortName: "Trap Bar Deadlift",
    primMusc1: "hamstrings",
    primMusc2: "lowerBack",
    secMusc1: "glutes",
    secMusc2: "quads",
    exCat: "reps",
    weight: 135,
    equip1: "trapBar",
    equip2: "",
    tip: "",
    desc: "Deadlifts are an essential exercise that help build both strength, and muscle. They can be completed with dumbbells, a barbell, a trap bar or cables. The exercise focuses on your whole body, with an emphasis on your lower back.  ",
    calibKey: "trapBarDeadlift",
    "AI Alternative Exercises":
      "dumbbellBulgarianSplitSquat,gobletSquat,barbellRackPull",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "e84f952b13",
  },
  trapBarShrug: {
    key: "trapBarShrug",
    name: "Trap Bar Shrug",
    altNames: "",
    plurName: "Trap Bar Shrug",
    shortName: "Trap Bar Shrug",
    primMusc1: "traps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 135,
    equip1: "trapBar",
    equip2: "",
    tip: "",
    desc: "The Shoulder Shrug is a critical exercise in strengthening the shoulders and developing the upper trapezius muscle. It can be performed with a barbell, dumbbells, trap bar, resistance bands, parallel bar, or a smith machine. To effectively shoulder shrug you must stand tall with hands placed shoulder width apart, and slowly raise the shoulders as high as possible, before slowly lowering them back to starting position.",
    calibKey: "trapBarShrug",
    "AI Alternative Exercises":
      "barbellDeadlift,trapBarDeadlift,barbellShrug,dumbbellShrug",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "8e36b09a7c",
  },
  cableTricepExtension: {
    key: "cableTricepExtension",
    name: "Tricep Pushdown / Extension (Bar)",
    altNames: "",
    plurName: "Tricep Pushdown / Extension (Bar)",
    shortName: "Tri Push Down (Bar)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableTricepExtensionBar",
    "AI Alternative Exercises":
      "cableTricepExtensionVBar,cableFrontRaiseSingleArm,cableStraightArmPulldown,cableRopeStraightArmPulldown,cableRopeTricepExtension",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "da88910ead",
  },
  cableRopeTricepExtension: {
    key: "cableRopeTricepExtension",
    name: "Tricep Pushdown / Extension (Rope)",
    altNames: "",
    plurName: "Tricep Pushdown / Extension (Rope)",
    shortName: "Tri Push Down (Rope)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableTricepExtensionRope",
    "AI Alternative Exercises":
      "cableTricepExtensionVBar,cableRopeStraightArmPulldown,dumbbellHammerCurl,cableRopeBicepCurl",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 70,
    hash: "33e5dd0cc2",
  },
  cableTricepExtensionVBar: {
    key: "cableTricepExtensionVBar",
    name: "Tricep Pushdown / Extension (V Bar)",
    altNames: "",
    plurName: "Tricep Pushdown / Extension (V Bar)",
    shortName: "Tri Push Down (V Bar)",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Tricep extensions are an excellent push-type isolation exercise which works on triceps, shoulders chest, lats, and forearms. Can be performed with a cable in a higher or lower position.",
    calibKey: "cableTricepExtensionVbar",
    "AI Alternative Exercises": "cableTricepExtension,cableRopeTricepExtension",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 60,
    hash: "39d147acf8",
  },
  dumbbellTricepKickback: {
    key: "dumbbellTricepKickback",
    name: "Tricep Kickback",
    altNames: "",
    plurName: "Tricep Kickback",
    shortName: "Tricep Kickback",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "Tricep kickbacks are an isolated movement that allow you to build muscle and tone the back of your arms. They are completed with dumbbells. ",
    calibKey: "dumbbellTricepKickback",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 60,
    hash: "202f637390",
  },
  cableUprightRearDeltFly: {
    key: "cableUprightRearDeltFly",
    name: "Upright Cable Rear Delt Fly",
    altNames: "Reverse",
    plurName: "Upright Cable Rear Delt Fly",
    shortName: "Upright Cbl Rear Delt Fly",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 55,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "Rear delt fly is an excellent exercise that increases deltoid muscle definition and strength. It can be performed on the cable machine either upright or bent over.",
    calibKey: "cableRearDeltFlyUpright",
    "AI Alternative Exercises":
      "machineRearDeltFly,cableBentoverRearDeltFly,cableRopeStraightArmPulldown,cableCrossoverHigh",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 40,
    hash: "abbb46f02c",
  },
  vBarTricepPushdown: {
    key: "vBarTricepPushdown",
    name: "V-Bar Tricep Pushdown",
    altNames: "",
    plurName: "V-Bar Tricep Pushdown",
    shortName: "V-Bar Tri Push Down",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableTricepExtensionVbar",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "a9c0acc57d",
  },
  vSit: {
    key: "vSit",
    name: "V-Sit",
    altNames: "",
    plurName: "V-sits",
    shortName: "V-Sit",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Sit with your legs extended and torso off the ground, your body forming a V shape.",
    calibKey: "bodyweightVSit",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "778e317f17",
  },
  dumbbellWalkingLunge: {
    key: "dumbbellWalkingLunge",
    name: "Dumbbell Walking Lunge",
    altNames: "",
    plurName: "Dumbbell Walking Lunges",
    shortName: "Walking Lunge",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellLungeWalking",
    "AI Alternative Exercises":
      "dumbbellBulgarianSplitSquat,dumbbellInclineBenchPress,dumbbellLunge",
    calCoef: 1.0,
    TVLFactor: 2,
    pop: 50,
    hash: "291fe5023f",
  },
  wallBalls: {
    key: "wallBalls",
    name: "Wall Balls",
    altNames: "",
    plurName: "Wall Balls",
    shortName: "Wall Balls",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "wallBalls",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 20,
    hash: "4d02081d9f",
  },
  wallSit: {
    key: "wallSit",
    name: "Wall Sit",
    altNames: "",
    plurName: "Wall Sit",
    shortName: "Wall Sit",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "Sit placing your ack against the wall, shoulder-width apart, and a little ways out from the wall. Keeping your back against the wall, lower your hips until your knees form right angles",
    calibKey: "bodyweightWallSit",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 40,
    hash: "c5f90e3c38",
  },
  machineRowWideGrip: {
    key: "machineRowWideGrip",
    name: "Machine Row Wide Grip ",
    altNames: "",
    plurName: "Wide Grip Machine Row",
    shortName: "Wide Grip Row",
    primMusc1: "traps",
    primMusc2: "shoulders",
    secMusc1: "biceps",
    secMusc2: "lats",
    exCat: "reps",
    weight: 50,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineRowWideTraditional",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 60,
    hash: "8f1c448ba6",
  },
  dumbbellCrossBodyHammercurl: {
    key: "dumbbellCrossBodyHammercurl",
    name: "Cross Body Hammer Curl",
    altNames: "",
    plurName: "Cross Body Hammer Curl",
    shortName: "X-Body Hammer Curl",
    primMusc1: "biceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellBicepCurlHammerCrossBody",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 30,
    hash: "a68abbe5c0",
  },
  crossCableTricepExtension: {
    key: "crossCableTricepExtension",
    name: "Cross Cable Tricep Extension",
    altNames: "",
    plurName: "Cross Cable Tricep Extension",
    shortName: "X-Cable Tri Ext.",
    primMusc1: "triceps",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 30,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "crossCableTricepExtension",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 50,
    hash: "b5d2d8f326",
  },
  squatsY: {
    key: "squatsY",
    name: "Y Squat",
    altNames: "",
    plurName: "Y Squat",
    shortName: "Y Squats",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightYSquats",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 30,
    hash: "3d723953f7",
  },
  zottmanCurl: {
    key: "zottmanCurl",
    name: "Zottman Curls",
    altNames: "",
    plurName: "Zottman Curls",
    shortName: "Zottman Curls",
    primMusc1: "biceps",
    primMusc2: "forearms",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellZottmanCurl",
    "AI Alternative Exercises": "bicycleCrunch,resistanceBandBicepCurl",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "c38c180855",
  },
  dumbbellHammerBenchPress: {
    key: "dumbbellHammerBenchPress",
    name: "Dumbbell Hammer Bench Press",
    altNames: "",
    plurName: "Dumbbell Hammer Bench Press",
    shortName: "DB Hammer Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellHammerBenchPress",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "505cc491be",
  },
  dumbbellCloseGripHammerBenchPress: {
    key: "dumbbellCloseGripHammerBenchPress",
    name: "Dumbbell Close Grip Hammer Bench Press (Crush Press)",
    altNames: "Crush Press",
    plurName: "Dumbbell Close Grip Hammer Bench Press (Crush Press)",
    shortName: "DB Close Grip Hammer Bench",
    primMusc1: "chest",
    primMusc2: "",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellCloseGripHammerBenchPress",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "3a725747ce",
  },
  dumbbellRomanianDeadlift: {
    key: "dumbbellRomanianDeadlift",
    name: "Dumbbell Romanian Deadlift",
    altNames: "",
    plurName: "Dumbbell Romanian Deadlifts",
    shortName: "DB RDL",
    primMusc1: "glutes",
    primMusc2: "hamstrings",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellRomanianDeadlift",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "d85112d7db",
  },
  singleArmCableRow: {
    key: "singleArmCableRow",
    name: "Single Arm Cable Row",
    altNames: "",
    plurName: "Single Arm Cable Rows",
    shortName: "Single Arm Cable Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "singleArmCableRow",
    "AI Alternative Exercises": "",
    calCoef: 0.7,
    TVLFactor: 2,
    pop: 40,
    hash: "2097a334ac",
  },
  vSquatMachineFacingOut: {
    key: "vSquatMachineFacingOut",
    name: "V Squat Machine",
    altNames: "",
    plurName: "V Squat Machine",
    shortName: "V Squat Machine",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "vSquatMachineFacingOut",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "3457c14ef6",
  },
  vSquatMachineFacingIn: {
    key: "vSquatMachineFacingIn",
    name: "V Squat Machine (Facing In / Reverse)",
    altNames: "",
    plurName: "V Squat Machine (Facing In / Reverse)",
    shortName: "V Squat Machine (Facing In)",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "vSquatMachineFacingIn",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "0ac7a1a32b",
  },
  pendulumSquatMachine: {
    key: "pendulumSquatMachine",
    name: "Pendulum Squat Machine",
    altNames: "",
    plurName: "Pendulum Squat Machine",
    shortName: "Pendulum Squat Machine",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "pendulumSquatMachine",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "ac8f0a2fb0",
  },
  bodyweightDeclineSitUp: {
    key: "bodyweightDeclineSitUp",
    name: "Decline Sit Up",
    altNames: "",
    plurName: "Decline Sit Ups",
    shortName: "Decline Sit Up",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "bodyweightDeclineSitUp",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "e992fc0ab1",
  },
  smithMachineHipThruster: {
    key: "smithMachineHipThruster",
    name: "Smith Machine Hip Thruster",
    altNames: "Glute Bridge",
    plurName: "Smith Machine Hip Thrusters",
    shortName: "Smith M. Hip Thruster",
    primMusc1: "glutes",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineHipThruster",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "3c2b4c1dd0",
  },
  sledDrag: {
    key: "sledDrag",
    name: "Sled Drag",
    altNames: "",
    plurName: "Sled Drag",
    shortName: "Sled Drag",
    primMusc1: "quads",
    primMusc2: "hamstrings",
    secMusc1: "calves",
    secMusc2: "glutes",
    exCat: "reps",
    weight: 90,
    equip1: "other",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "sledDrag",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "0d1a5f109b",
  },
  barbellFrontRaise: {
    key: "barbellFrontRaise",
    name: "Barbell Front Raise",
    altNames: "",
    plurName: "Barbell Front Raise",
    shortName: "Barbell Front Raise",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "lats",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellFrontRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.0,
    TVLFactor: 1,
    pop: 40,
    hash: "9f617f16ca",
  },
  stabilityBallPushUp: {
    key: "stabilityBallPushUp",
    name: "Stability Ball Push Up",
    altNames: "",
    plurName: "Stability Ball Push Ups",
    shortName: "Stability Ball Push Ups",
    primMusc1: "chest",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "stabilityBallPushUp",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "5757ef9411",
  },
  barbellAbRollout: {
    key: "barbellAbRollout",
    name: "Barbell Ab Rollout",
    altNames: "",
    plurName: "Barbell Ab Rollout",
    shortName: "Barbell Ab Rollout",
    primMusc1: "abs",
    primMusc2: "lowerBack",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellAbRollout",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "b618ae560d",
  },
  barbellBehindBackShrug: {
    key: "barbellBehindBackShrug",
    name: "Barbell Behind Back Shrug",
    altNames: "",
    plurName: "Barbell Behind Back Shrugs",
    shortName: "Barbell Behind Back Shrug",
    primMusc1: "traps",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellBehindBackShrug",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "d8cdc610fa",
  },
  barbellBicepDragCurl: {
    key: "barbellBicepDragCurl",
    name: "Barbell Bicep Drag Curl",
    altNames: "",
    plurName: "Barbell Bicep Drag Curls",
    shortName: "Barbell Drag Curl",
    primMusc1: "biceps",
    primMusc2: "forearms",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellBicepDragCurl",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "7b221ec975",
  },
  barbellCurtsyLunge: {
    key: "barbellCurtsyLunge",
    name: "Barbell Curtsy Lunge",
    altNames: "",
    plurName: "Barbell Curtsy Lunges",
    shortName: "Barbell Curtsy Lunge",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "calves",
    secMusc2: "",
    exCat: "reps",
    weight: 50,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellCurtsyLunge",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "dcd153fb3f",
  },
  barbellFloorPress: {
    key: "barbellFloorPress",
    name: "Barbell Floor Press",
    altNames: "",
    plurName: "Barbell Floor Press",
    shortName: "Barbell Floor Press",
    primMusc1: "triceps",
    primMusc2: "chest",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellFloorPress",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "843c82f5e8",
  },
  zercherSquat: {
    key: "zercherSquat",
    name: "Zercher Squat",
    altNames: "",
    plurName: "Zercher Squats",
    shortName: "Zercher Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "zercherSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "975b228cd3",
  },
  aroundTheWorlds: {
    key: "aroundTheWorlds",
    name: "Around The Worlds",
    altNames: "",
    plurName: "Around The Worlds",
    shortName: "Around The Worlds",
    primMusc1: "triceps",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "aroundTheWorlds",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "b47b82fa6f",
  },
  elevatedPlank: {
    key: "elevatedPlank",
    name: "Elevated Plank",
    altNames: "",
    plurName: "Elevated Planks",
    shortName: "Elevated Plank",
    primMusc1: "abs",
    primMusc2: "obliques",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "elevatedPlank",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "e125bd2f05",
  },
  tatePress: {
    key: "tatePress",
    name: "Tate Press",
    altNames: "",
    plurName: "Tate Presses",
    shortName: "Tate Press",
    primMusc1: "triceps",
    primMusc2: "shoulders",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "tatePress",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "33cf53a6c3",
  },
  ezBarOverheadTricepExtension: {
    key: "ezBarOverheadTricepExtension",
    name: "EZ Bar Overhead Tricep Extension",
    altNames: "",
    plurName: "EZ Bar Overhead Tricep Extension",
    shortName: "EZ Overhead Tricep Ext.",
    primMusc1: "triceps",
    primMusc2: "shoulders",
    secMusc1: "forearms",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "ezBar",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "ezBarOverheadTricepExtension",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "c3c83b17a3",
  },
  deadHang: {
    key: "deadHang",
    name: "Dead Hang",
    altNames: "",
    plurName: "Dead Hang",
    shortName: "Dead Hangs",
    primMusc1: "forearms",
    primMusc2: "shoulders",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "time",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "deadHang",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "590f6230be",
  },
  kettlebellStandingCalfRaise: {
    key: "kettlebellStandingCalfRaise",
    name: "Standing Kettlebell Calf Raise",
    altNames: "",
    plurName: "Standing Kettlebell Calf Raises",
    shortName: "Kettlebell Standing Calf Raise",
    primMusc1: "calves",
    primMusc2: "hamstrings",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellStandingCalfRaise",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "5972e0a118",
  },
  resistanceBandPullUp: {
    key: "resistanceBandPullUp",
    name: "Resistance Band Pull Up",
    altNames: "",
    plurName: "Resistance Band Pull Ups",
    shortName: "Band Pull Up",
    primMusc1: "biceps",
    primMusc2: "forearms",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandPullUp",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "99454498ed",
  },
  resistanceBandChinUp: {
    key: "resistanceBandChinUp",
    name: "Resistance Band Chin Up",
    altNames: "",
    plurName: "Resistance Band Chin Ups",
    shortName: "Band Chin Up",
    primMusc1: "biceps",
    primMusc2: "forearms",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandChinUp",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "6f12db44e2",
  },
  resistanceBandHammerPullUp: {
    key: "resistanceBandHammerPullUp",
    name: "Resistance Band Hammer Grip Pull Up",
    altNames: "",
    plurName: "Resistance Band Hammer Grip Pull Ups",
    shortName: "Band Hammer Pull Up",
    primMusc1: "biceps",
    primMusc2: "forearms",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandHammerPullUp",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "822a22b78b",
  },
  resistanceBandOverheadSquat: {
    key: "resistanceBandOverheadSquat",
    name: "Resistance Band Overhead Squat",
    altNames: "",
    plurName: "Resistance Band Overhead Squats",
    shortName: "Band Overhead Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandOverheadSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "d9d7ca5f3b",
  },
  resistanceBandBulgarianSplitSquat: {
    key: "resistanceBandBulgarianSplitSquat",
    name: "Resistance Band Bulgarian Split Squat",
    altNames: "",
    plurName: "Resistance Band Bulgarian Split Squats",
    shortName: "Band Bulgarian Split Squat",
    primMusc1: "quads",
    primMusc2: "glutes",
    secMusc1: "hamstrings",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandBulgarianSplitSquat",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "47e957594e",
  },
  resistanceBandHamstringCurl: {
    key: "resistanceBandHamstringCurl",
    name: "Resistance Band Hamstring Curl",
    altNames: "",
    plurName: "Resistance Band Hamstring Curls",
    shortName: "Band Hamstring Curl",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "abs",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bands",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "resistanceBandHamstringCurl",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "a4a87ffd2e",
  },
  smithMachineSingleLegDeadlift: {
    key: "smithMachineSingleLegDeadlift",
    name: "Smith Machine Single Leg Deadlift",
    altNames: "",
    plurName: "Smith Machine Single Leg Deadlifts",
    shortName: "Smith M. Single Leg Deadlift",
    primMusc1: "hamstrings",
    primMusc2: "glutes",
    secMusc1: "lowerBack",
    secMusc2: "",
    exCat: "reps",
    weight: 90,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineSingleLegDeadlift",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "25e7c9093c",
  },
  smithMachineDragCurl: {
    key: "smithMachineDragCurl",
    name: "Smith Machine Drag Curl",
    altNames: "",
    plurName: "Smith Machine Drag Curls",
    shortName: "Smith M. Drag Curl",
    primMusc1: "biceps",
    primMusc2: "forearms",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "smithMachine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "smithMachineDragCurl",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "81c079db3b",
  },
  barbellPullOver: {
    key: "barbellPullOver",
    name: "Barbell Pull Over",
    altNames: "",
    plurName: "Barbell Pull Overs",
    shortName: "Barbell Pull Over",
    primMusc1: "lats",
    primMusc2: "chest",
    secMusc1: "triceps",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellPullOver",
    "AI Alternative Exercises": "",
    calCoef: 0.4,
    TVLFactor: 1,
    pop: 40,
    hash: "0fdb175dae",
  },
  machineHackSquatFacingIn: {
    key: "machineHackSquatFacingIn",
    name: "Hack Squat (Facing In)",
    altNames: "",
    plurName: "Hack Squats (Facing In)",
    shortName: "Hack Squat (Facing In)",
    primMusc1: "glutes",
    primMusc2: "hamstrings",
    secMusc1: "quads",
    secMusc2: "calves",
    exCat: "reps",
    weight: 90,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineHackSquatFacingIn",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "79f4b83917",
  },
  barbellSplitSquat: {
    key: "barbellSplitSquat",
    name: "Barbell Split Squat",
    altNames: "",
    plurName: "Barbell Split Squats",
    shortName: "Barbell Split Squats",
    primMusc1: "glutes",
    primMusc2: "hamstrings",
    secMusc1: "quads",
    secMusc2: "",
    exCat: "reps",
    weight: 45,
    equip1: "barbell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "barbellSplitSquat",
    "AI Alternative Exercises": "",
    calCoef: 1.0,
    TVLFactor: 1,
    pop: 40,
    hash: "0d1f5b06ff",
  },
  machineLatPulldownWide: {
    key: "machineLatPulldownWide",
    name: "Machine Lat Pull Down (Wide)",
    altNames: "",
    plurName: "Machine Lat Pull Downs (Wide)",
    shortName: "Mach. Lat Pull Down",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "biceps",
    secMusc2: "",
    exCat: "reps",
    weight: 40,
    equip1: "machine",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "machineLatPulldownWide",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 1,
    pop: 40,
    hash: "816e78d441",
  },
  kettlebellWindmill: {
    key: "kettlebellWindmill",
    name: "Kettlebell Windmill",
    altNames: "",
    plurName: "Kettlebell Windmills",
    shortName: "Kettlebell Windmill",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "kettlebell",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "kettlebellWindmill",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 40,
    hash: "91de8c0586",
  },
  heelTaps: {
    key: "heelTaps",
    name: "Heel Taps",
    altNames: "Toe Touches",
    plurName: "Heel Taps",
    shortName: "Heel Taps",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "heelTaps",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 1,
    pop: 40,
    hash: "18ac7d8c93",
  },
  cableBentoverRearDeltFlySingleArm: {
    key: "cableBentoverRearDeltFlySingleArm",
    name: "Bent Over Cable Rear Delt Fly (Single Arm)",
    altNames: "",
    plurName: "Bent Over Cable Rear Delt Fly (Single Arm)",
    shortName: "Bentover Delt Fly (Single)",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableBentoverRearDeltFlySingleArm",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 2,
    pop: 30,
    hash: "8822e891fa",
  },
  cableUprightRearDeltFlySingleArm: {
    key: "cableUprightRearDeltFlySingleArm",
    name: "Upright Cable Rear Delt Fly (Single Arm) ",
    altNames: "",
    plurName: "Upright Cable Rear Delt Fly (Single Arm) ",
    shortName: "Upright Delt Fly (Single)",
    primMusc1: "shoulders",
    primMusc2: "",
    secMusc1: "",
    secMusc2: "",
    exCat: "reps",
    weight: 20,
    equip1: "cables",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "cableUprightRearDeltFlySingleArm",
    "AI Alternative Exercises": "",
    calCoef: 0.6,
    TVLFactor: 2,
    pop: 30,
    hash: "275e8bf03a",
  },
  invertedRow: {
    key: "invertedRow",
    name: "Inverted Row",
    altNames: "",
    plurName: "Inverted Rows",
    shortName: "Inverted Row",
    primMusc1: "lats",
    primMusc2: "",
    secMusc1: "shoulders",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "invertedRow",
    "AI Alternative Exercises": "",
    calCoef: 0.5,
    TVLFactor: 1,
    pop: 30,
    hash: "2331c67913",
  },
  hollowRock: {
    key: "hollowRock",
    name: "Hollow Rock",
    altNames: "",
    plurName: "Hollow Rock",
    shortName: "Hollow Rock",
    primMusc1: "abs",
    primMusc2: "",
    secMusc1: "obliques",
    secMusc2: "",
    exCat: "reps",
    weight: 0,
    equip1: "bodyWeight",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "hollowRock",
    "AI Alternative Exercises": "",
    calCoef: 0.3,
    TVLFactor: 1,
    pop: 30,
    hash: "55af6d2fe3",
  },
  dumbbellThruster: {
    key: "dumbbellThruster",
    name: "Dumbbell Thruster",
    altNames: "",
    plurName: "Dumbbell Thrusters",
    shortName: "Dumbbell Thruster",
    primMusc1: "quads",
    primMusc2: "shoulders",
    secMusc1: "glutes",
    secMusc2: "",
    exCat: "reps",
    weight: 25,
    equip1: "dumbbells",
    equip2: "",
    tip: "",
    desc: "",
    calibKey: "dumbbellThruster",
    "AI Alternative Exercises": "",
    calCoef: 0.8,
    TVLFactor: 2,
    pop: 30,
    hash: "24df6b3eaa",
  },
};
