import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Lottie from "lottie-react";
import loadingAnimation from "./animation/loading.json";
import Home from "./Home";
import { ConfigProvider } from "antd";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import AuthInput from "./AuthInput";
import { ProviderAuth, useAuth } from "./context/auth";
import WorkoutNames from "./WorkoutNames";

// Load train-icon.svg as component
import TrainIcon from "./train-icon.svg";
import AIWorkoutTemplates from "./WorkoutTemplate";
const GenLayout = ({}) => {
  const match = useLocation();

  let publicFlag = match.pathname.search(/\/public\/[a-zA-Z0-9]+/) == 0;

  return (
    <div className="body">
      {publicFlag ? null : (
        <div className="header">
          <img src={TrainIcon} alt="Train Logo" className="train-img" />

          <div className="title">Train Experiments</div>
        </div>
      )}
      <div className="layout">
        <div>
          <div className="outlet">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const importRouter = createBrowserRouter([
  {
    path: "/",
    element: <GenLayout />,

    children: [
      {
        path: "",
        // element: <Home />,
        element: <Navigate to="/workout-templates" />,
      },
      {
        path: "*",
        // element: <Home />,
        element: <Navigate to="/workout-templates" />,
      },
      {
        path: "/workout-names",
        element: <WorkoutNames />,
      },
      {
        path: "/workout-templates",
        element: <AIWorkoutTemplates />,
      },
    ],
  },
]);

const authRouter = createBrowserRouter([
  {
    path: "/",
    element: <GenLayout />,
    children: [
      {
        path: "",
        element: <AuthInput />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

const BaseApp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const customToken = urlParams.get("customToken");

  const [islogginIn, setIsLogginIn] = React.useState(customToken? true: false);
  const auth = useAuth();
  useEffect(() => {
    // check if custom token is present in url
    if (customToken && customToken !== "" && auth.isAuthenticated === false) {
      login(customToken);
    }
  }, []);

  const login = async (customToken: string) => {
    await auth.loginWithToken(customToken);
    // Redirect to home page after removing customToken from url
    urlParams.delete("customToken");
    window.history.replaceState({}, "", `${window.location.pathname}`);
    window.location.reload();
    setIsLogginIn(false);
  };
  if (islogginIn ) {
    return <Lottie animationData={loadingAnimation} />;
  }

  if (auth.loading) {
    return <Lottie animationData={loadingAnimation} />;
  }
  if (auth.isAuthenticated) {
    return <RouterProvider router={importRouter} />;
  } else {
    return <RouterProvider router={authRouter} />;
  }
};

function App() {
  return (
    <ProviderAuth>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#667eea",
          },
        }}
      >
        <div className="App">
          {/* <Lottie animationData={loadingAnimation} /> */}
          <BaseApp />
        </div>
      </ConfigProvider>
    </ProviderAuth>
  );
}

export default App;
