import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import React from "react";

import { ArrowRightOutlined } from "@ant-design/icons";
import { useAuth } from "./context/auth";

const AuthInput = () => {
  const [token, setToken] = React.useState("");
  const auth = useAuth();

  const login = async () => {
    await auth.loginWithToken(token);
    message.success("Logged in successfully");
  };
  return (
    <div className="experiment">
      <Card>
        <div className="experiment-title" style={{
          textAlign: "left"
        
        }}>Hi, Please log in to continue...</div>

        <Input
          placeholder="Custom Token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          onPressEnter={login}
          suffix={<ArrowRightOutlined />}
          style={{
            marginTop: "1rem"
          }}
        />
      </Card>
    </div>
  );
};

export default AuthInput;
