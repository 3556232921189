import React, { useEffect } from "react";
import { useAuth } from "./context/auth";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, firestore } from "./firebase";
import { Button, Card, Divider, Modal, Row, Space } from "antd";
import moment from "moment";
import { API_BASE_URL } from "./constant";
import axios from "axios";
import { HeartOutlined } from "@ant-design/icons";

const WorkoutDetails = ({ workout }: { workout: any }) => {
  const [state, setState] = React.useState({
    suggestions: [] as any[],
    feedback: {} as any,
    isLoading: false,
    showModal: false,
  });

  const loadTitleSuggestions = async () => {
    let url = `${API_BASE_URL}arnold/suggest-name`;
    let data = {
      workoutID: workout.workoutID,
    };
    setState({ ...state, isLoading: true });
    try {
      let idToken = await auth.currentUser?.getIdToken();
      let headers = {
        Authorization: `Bearer ${idToken}`,
      };
      let res = await axios.post(url, data, { headers });
      setState({
        ...state,
        suggestions: res.data?.suggestions || [],
        isLoading: false,
        showModal: true,
      });
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const toggleFeedback = async (suggestion: string) => {
    let existingFeedback = state.feedback;

    if (existingFeedback[suggestion]) {
      delete existingFeedback[suggestion];
    } else {
      existingFeedback[suggestion] = true;
    }
    setState({ ...state, feedback: existingFeedback });
  };

  return (
    <div className="workout-details">
      <Modal
        title="Suggestions"
        visible={state.showModal}
        footer={null}
        onCancel={() => setState({ ...state, showModal: false })}
      >
        <Space
          direction="vertical"
          style={{ width: "100%" }}
          split={<Divider style={{ margin: "0.5rem 0" }} />}
        >
          {state.suggestions.map((suggestion) => {
            return (
              <div>
                {suggestion}
                <Button
                  onClick={() => toggleFeedback(suggestion)}
                  type="link"
                  style={{ float: "right" }}
                >
                  <i
                    className={
                      state.feedback[suggestion]
                        ? "fa-solid fa-heart"
                        : "fa-regular fa-heart"
                    }
                    style={{
                      color: state.feedback[suggestion] ? "#ff8080" : "black",
                    }}
                  ></i>
                </Button>
              </div>
            );
          })}
        </Space>
      </Modal>
      <Row align="top" justify="space-between">
        <div>
          <div className="title">{workout.workoutName}</div>
          <div className="subtitle">
            {moment(workout.timeStarted * 1000).fromNow()}
          </div>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => loadTitleSuggestions()}
            loading={state.isLoading}
            size="small"
          >
            Suggest
          </Button>
        </div>
      </Row>
    </div>
  );
};
const WorkoutNames = () => {
  const auth = useAuth();
  const [workouts, setWorkouts] = React.useState<any>([]);
  useEffect(() => {
    loadWorkouts();
  }, []);

  const loadWorkouts = async () => {
    let res = await getDocs(
      query(
        collection(firestore, "StrengthWorkout"),
        where("userID", "==", auth.user?.uid),
        orderBy("timeStarted", "desc"),
        limit(10)
      )
    );
    let workouts: any[] = [];
    res.forEach((doc: any) => {
      workouts.push(doc.data());
    });

    setWorkouts(workouts);
  };

  return (
    <Card className="content">
      <div
        className="experiment-title"
        style={{
          textAlign: "left",
        }}
      >
        Workout Names
      </div>

      <Space
        direction="vertical"
        split={
          <Divider
            style={{
              margin: "0.5rem 0",
            }}
          />
        }
        style={{ width: "100%", padding: "1rem 0rem" }}
      >
        {workouts.map((workout: any) => {
          return <WorkoutDetails workout={workout} key={workout.workoutID} />;
        })}
      </Space>
    </Card>
  );
};

export default WorkoutNames;
