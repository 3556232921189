import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  message,
} from "antd";
import React, { useEffect } from "react";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "./constant";
import axios from "axios";
import Lottie from "lottie-react";
import loadingAnimation from "./animation/loading.json";
import { useAuth } from "./context/auth";
import { database, firestore } from "./firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { onValue, ref } from "firebase/database";
import { EXERCISE_DETAILS } from "./exerciseDetails";

import { SaveOutlined, RedoOutlined } from "@ant-design/icons";
const muscleGroups = [
  { name: "Triceps", value: "triceps" },
  { name: "Shoulders", value: "shoulders" },
  { name: "Abs", value: "abs" },
  { name: "Quads", value: "quads" },
  { name: "Lats", value: "lats" },
  { name: "Chest", value: "chest" },
  { name: "Lower Back", value: "lowerBack" },
  { name: "Biceps", value: "biceps" },
  { name: "Calves", value: "calves" },
  { name: "Hamstrings", value: "hamstrings" },
  { name: "Forearms", value: "forearms" },
  { name: "Traps", value: "traps" },
  { name: "Glutes", value: "glutes" },
  { name: "Obliques", value: "obliques" },
];

const equipment = [
  { name: "Full Gym", value: "" },
  { name: "Barbell", value: "barbell" },
  { name: "Machine", value: "machine" },
  { name: "Body Weight", value: "bodyWeight" },
  { name: "Dumbbells", value: "dumbbells" },
  { name: "Bands", value: "bands" },
  { name: "Bench", value: "bench" },
  { name: "EZ Bar", value: "ezBar" },
  { name: "Kettlebell", value: "kettlebell" },
  { name: "Smith Machine", value: "smithMachine" },
  { name: "Trap Bar", value: "trapBar" },
];
const fitnessLevel = ["Beginner", "Intermediate", "Advanced"];

const workoutFrequency = [
  "Never",
  "1-2 Days a Week",
  "3-4 Days a Week",
  "4+ Days a Week",
  "Multiple Times a Day",
];

const goal = [
  "Bodybuilding",
  "Tone Muscles",
  "General Fitness",
  "Strength Training",
  "Bodybuilding",
  "Powerlifting",
  "Olympic Weightlifting",
  "Weight Loss",
];

const AIWorkoutConfig = ({
  onGenerate,
}: {
  onGenerate: (state: any) => void;
}) => {
  //
  const [state, setState] = React.useState({
    loaded: false,
    duration: 60,
    intensity: "medium" as "low" | "medium" | "high",
    targetMuscleGroup: [] as string[],
    restTimer: 2 as number,
    onlyFullbody: false,
    equipment: [] as string[],
    inlcudeWarmupSet: false,
    includeSuperset: false,
    creativityLevel: "medium" as "low" | "medium" | "high",
    fitnessLevel: "Beginner" as string,
    workoutFrequency: "1-2 Days a Week" as string,
    goal: "Bodybuilding" as string,
  });
  // Persist config to local storage
  useEffect(() => {
    if (state && state.loaded) {
      localStorage.setItem("ai-workout-config", JSON.stringify(state));
    }
  }, [state]);

  useEffect(() => {
    let config = localStorage.getItem("ai-workout-config");
    if (config) {
      console.log("Config", JSON.parse(config as string));
      setState((prev) => ({
        prev,
        ...JSON.parse(config as string),
        loaded: true,
      }));
    } else {
      setState((prev) => ({ ...prev, loaded: true }));
    }
  }, []);

  return (
    <div className="experiment">
      <div className="experiment-title">AI Workout Templates</div>
      <div className="section">
        <div className="section-title">General Fitness</div>

        <Space
          direction="vertical"
          split={
            <Divider
              style={{
                margin: "4px 0",
              }}
            />
          }
          style={{ width: "100%" }}
          className="section-content"
        >
          <Row justify="space-between" align="middle">
            <div
              style={{
                width: "100%",
              }}
            >
              <div className="field-label">Goal</div>
              {/* <Input
                value={state.goal}
                placeholder="Goal"
                style={{
                  width: "100%",
                }}
                onChange={(e) =>
                  setState({
                    ...state,
                    goal: e.target.value,
                  })
                }
              /> */}
              <Select
                style={{ width: "100%" }}
                placeholder="Please select"
                value={state.goal}
                onChange={(value) =>
                  setState({
                    ...state,
                    goal: value,
                  })
                }
              >
                {goal.map((group) => (
                  <Select.Option key={group} value={group}>
                    {group}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Row>
          <Row justify="space-between" align="middle">
            <div className="field-label">Fitness Level</div>
            {/* <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              value={state.fitnessLevel}
              onChange={(value) =>
                setState({
                  ...state,
                  fitnessLevel: value,
                })
              }
            >
              {fitnessLevel.map((group) => (
                <Select.Option key={group} value={group}>
                  {group}
                </Select.Option>
              ))}
            </Select> */}
            <Radio.Group
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              value={state.fitnessLevel}
              onChange={(e) =>
                setState({
                  ...state,
                  fitnessLevel: e.target.value,
                })
              }
            >
              {fitnessLevel.map((group) => (
                <Radio.Button key={group} value={group}>
                  {group}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Row>

          <Row justify="space-between" align="middle">
            <div className="field-label">Workout Frequency</div>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              value={state.workoutFrequency}
              onChange={(value) =>
                setState({
                  ...state,
                  workoutFrequency: value as string,
                })
              }
            >
              {workoutFrequency.map((group) => (
                <Select.Option key={group} value={group}>
                  {group}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </Space>
      </div>

      <div className="section">
        <div className="section-title">Workout Preferences</div>

        <Space
          direction="vertical"
          split={
            <Divider
              style={{
                margin: "4px 0",
              }}
            />
          }
          style={{ width: "100%" }}
          className="section-content"
        >
          <Row justify="space-between" align="middle">
            <div>
              <div className="field-label">Duration(min)</div>
              <InputNumber
                min={15}
                max={120}
                defaultValue={60}
                step={5}
                onChange={(value) =>
                  setState({
                    ...state,
                    duration: value as number,
                  })
                }
                style={{
                  width: 150,
                }}
                value={state.duration}
              />
            </div>
            <div>
              <div className="field-label">Intensity</div>
              <Select
                defaultValue="medium"
                style={{ width: 150 }}
                onChange={(value) =>
                  setState({
                    ...state,
                    intensity: value as "low" | "medium" | "high",
                  })
                }
                value={state.intensity}
              >
                <Select.Option value="low">Low</Select.Option>
                <Select.Option value="medium">Medium</Select.Option>
                <Select.Option value="high">High</Select.Option>
              </Select>
            </div>
            {/* <div>
              <div className="field-label">Rest Timer(sec.)</div>
              <InputNumber
                min={0}
                max={240}
                defaultValue={90}
                step={5}
                onChange={(value) =>
                  setState({
                    ...state,
                    restTimer: value as number,
                  })
                }
                value={state.restTimer}
              />
            </div> */}
          </Row>
          <Row justify="space-between" align="middle">
            <div className="field-label">Target Muscle Groups</div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              value={state.targetMuscleGroup}
              onChange={(value) =>
                setState({
                  ...state,
                  targetMuscleGroup: value as string[],
                })
              }
            >
              {muscleGroups.map((group) => (
                <Select.Option key={group.value} value={group.value}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Row>

          <Row justify="space-between" align="middle">
            <div className="field-label">Equipment</div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              value={state.equipment}
              onChange={(value) =>
                setState({
                  ...state,
                  equipment: value as string[],
                })
              }
              disabled={state.onlyFullbody}
            >
              {equipment.map((group) => (
                <Select.Option key={group.value} value={group.value}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>

            {/* <Row
              justify="space-between"
              align="middle"
              style={{
                width: "100%",
                margin: "10px 0",
                padding: "6px 0",
              }}
            >
              <div className="field-label">Only Bodyweight Exercises</div>
              <Switch
                checked={state.onlyFullbody}
                onChange={(checked) =>
                  setState({ ...state, onlyFullbody: checked })
                }
              />
            </Row> */}
          </Row>
        </Space>
      </div>

      <div className="section">
        <div className="section-title">Advanced Preferences</div>

        <Space
          direction="vertical"
          split={
            <Divider
              style={{
                margin: "4px 0",
              }}
            />
          }
          style={{ width: "100%" }}
          className="section-content"
        >
          <Row justify="space-between" align="middle">
            <div className="field-label">Include Warmup Set</div>
            <Switch
              checked={state.inlcudeWarmupSet}
              onChange={(checked) =>
                setState({ ...state, inlcudeWarmupSet: checked })
              }
            />
          </Row>

          <Row justify="space-between" align="middle">
            <div className="field-label">Include Supersets</div>
            <Switch
              checked={state.includeSuperset}
              onChange={(checked) =>
                setState({ ...state, includeSuperset: checked })
              }
            />
          </Row>
        </Space>
      </div>

      <div className="section">
        <div className="section-title">AI Preferences</div>

        <Space
          direction="vertical"
          split={
            <Divider
              style={{
                margin: "4px 0",
              }}
            />
          }
          style={{ width: "100%" }}
          className="section-content"
        >
          <Row justify="space-between" align="middle">
            <div className="field-label">Creative Level</div>
            {/* <Switch
                checked={state.onlyFullbody}
                onChange={(checked) =>
                  setState({ ...state, inlcudeWarmupSet: checked })
                }
              /> */}
            <Row
              justify="center"
              align="middle"
              style={{
                width: "100%",
                margin: "10px 0",
              }}
            >
              <Radio.Group
                value={state.creativityLevel}
                onChange={(e) =>
                  setState({
                    ...state,
                    creativityLevel: e.target.value,
                  })
                }
              >
                <Radio.Button value="low">Low</Radio.Button>
                <Radio.Button value="medium">Medium</Radio.Button>
                <Radio.Button value="high">High</Radio.Button>
              </Radio.Group>
            </Row>
          </Row>
        </Space>
      </div>
      <Button
        type="primary"
        style={{ width: "100%", marginTop: 20 }}
        className="generate-workout"
        onClick={() => onGenerate(state)}
      >
        Generate Workout <ArrowRightOutlined />
      </Button>
    </div>
  );
};

const TemplateView = ({
  templateId,
  userId,
  criteria,
  regenerate,
}: {
  userId: string;
  templateId: string;
  criteria: any;
  regenerate: () => void;
}) => {
  console.log("userID", userId);
  // Form to save to profile
  const [form] = Form.useForm();

  const auth = useAuth();
  const [state, setState] = React.useState({
    data: {} as any | null,
    isLoading: true,
    isSavingToFirebase: false,
    showSaveModal: false,
    showFeedback: false,
    feedback: {
      emoji: "",
      comment: "",
    } as any,
  });

  const saveToFirestore = async (data: any) => {
    let ref = doc(firestore, `ArnoldTemplatesExperiment/${templateId}`);
    await setDoc(ref, {
      ...data,
      userId,
      templateId,
      inputCriteria: criteria,
    });
  };

  const addFeedback = async (feedback: any) => {
    let ref = doc(firestore, `ArnoldTemplatesExperiment/${templateId}`);
    await updateDoc(ref, {
      feedback: feedback,
    });
  };

  const saveTemplateToFirebase = async (data: any) => {
    let userId = auth.user?.uid;
    if (templateId && userId) {
      let idToken = await auth.user?.getIdToken();
      let headers = {
        Authorization: `Bearer ${idToken}`,
      };

      let url = `${API_BASE_URL}arnold/save-template`;
      let res = await axios.post(
        url,
        { templateId, userId, ...data },
        { headers }
      );

      setState({
        ...state,
        showSaveModal: false,
      });

      message.success("Template saved to profile");
    }
  };

  const templateAction = async (action: string) => {
    await addFeedback(state.feedback);
    if (action == "regenerate") {
      await regenerate();
    } else {
      setState({
        ...state,
        showSaveModal: true,
      });
    }
  };

  useEffect(() => {
    console.log(`arnoldTemplates/${userId}/${templateId}`);
    const docRef = ref(database, `arnoldTemplates/${userId}/${templateId}`);
    onValue(docRef, (snapshot: any) => {
      setState((prev) => ({
        ...prev,
        data: snapshot.val(),
        isLoading: false,
      }));

      // If finished, stop listening to the database
      if (snapshot.val()?.status == "completed") {
        console.log("Finished");
        saveToFirestore(snapshot.val());
        return;
      }
      console.log("Snapshot", snapshot.val());
    });
  }, [templateId]);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Modal
        title="Feedback"
        visible={state.showFeedback}
        onCancel={() =>
          setState({
            ...state,
            showFeedback: false,
          })
        }
        footer={null}
      >
        <Space
          direction="vertical"
          style={{ width: "100%" }}
          split={<Divider style={{ margin: "0.5rem 0" }} />}
        >
          <div>
            <div>How was the workout?</div>
            <Radio.Group
              onChange={(e) => {
                setState({
                  ...state,
                  feedback: {
                    ...state.feedback,
                    emoji: e.target.value,
                  },
                });
              }}
              value={state.feedback?.emoji}
            >
              <Radio.Button
                value={1}
                style={{
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                }}
              >
                {" "}
                🙁
              </Radio.Button>
              <Radio.Button
                value={2}
                style={{
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                🙂
              </Radio.Button>
              <Radio.Button
                value={3}
                style={{
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                😍
              </Radio.Button>
            </Radio.Group>
          </div>
          <div>
            <Input.TextArea
              value={state.feedback?.comment}
              onChange={(e) => {
                setState({
                  ...state,
                  feedback: {
                    ...state.feedback,
                    comment: e.target.value,
                  },
                });
              }}
              placeholder="Any feedback?"
              style={{
                width: "100%",
              }}
            />
          </div>

          <Button
            type="primary"
            style={{
              width: "100%",
            }}
            onClick={() => {
              console.log(state.feedback);
              setState({
                ...state,
                showFeedback: false,
              });

              addFeedback(state.feedback);
            }}
            disabled={state.feedback?.emoji ? false : true}
          >
            Submit
          </Button>
        </Space>
      </Modal>

      <Modal
        title="Save to templates"
        visible={state.showSaveModal}
        onCancel={() => {
          setState({
            ...state,
            showSaveModal: false,
          });
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={(values) => {
            console.log("Form values", values);
            saveTemplateToFirebase(values);
          }}
        >
          <Form.Item
            name="name"
            label="Workout Name"
            rules={[
              {
                required: true,
                message: "Please input the workout name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please input the workout description!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Row
        style={{
          marginBottom: "2rem",
        }}
        align="middle"
        justify="space-between"
      >
        <Typography.Title
          level={3}
          className="experiment-title"
          style={{
            margin: 0,
          }}
        >
          {state.data?.name || "Generated Workout"}
        </Typography.Title>
        {state.data && state.data?.status == "generating" && (
          <div>
            <span>
              <i
                className="fa-solid fa-circle-notch fa-spin"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "1.25rem",
                }}
              ></i>
            </span>
          </div>
        )}
        {state.data && state.data.status == "completed" && (
          <div>
            <span>
              <Button
                type="link"
                style={{
                  padding: 0,
                }}
                onClick={() => {
                  setState({
                    ...state,
                    showFeedback: true,
                  });
                }}
                size="large"
              >
                <i className="fa-regular fa-comment"></i>
              </Button>
            </span>
          </div>
        )}
      </Row>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
        split={<Divider style={{ margin: "0.5rem 0" }} />}
        className="template"
      >
        {!state.data?.setGroups || state.isLoading ? (
          <LoadingScreen />
        ) : (
          state.data &&
          state.data.setGroups &&
          state.data.setGroups.map((setGroup: any, idx: number) => {
            return (
              <Row style={{ width: "100%" }} key={idx}>
                <Row style={{ width: "100%" }}>
                  <div className="exercise">
                    {setGroup.exercises
                      .map(
                        (exercise: any) =>
                          EXERCISE_DETAILS[
                            exercise as keyof typeof EXERCISE_DETAILS
                          ]?.plurName || exercise
                      )
                      .join(",")}
                  </div>
                </Row>
                <Row style={{ width: "100%", padding: "0rem 1rem" }}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                      width: "100%",
                    }}
                    split={<Divider style={{ margin: "0rem 0" }} />}
                  >
                    <Col span={24} key={idx}>
                      <Row className="details-row">
                        <Col span={8}>
                          <div className="field-label">Weight</div>
                        </Col>
                        <Col span={8}>
                          <div className="field-label">Reps</div>
                        </Col>
                        <Col span={8}>
                          <div className="field-label">Rest Time</div>
                        </Col>
                      </Row>
                    </Col>
                    {setGroup.sets.map((set: any, idx: number) => (
                      <Col span={24} key={idx}>
                        <Row className="details-row">
                          <Col span={8}>
                            {/* <div className="field-label">Weight</div> */}
                            {set.weight ? <div>{set.weight} lbs</div> : "BW"}
                          </Col>
                          <Col span={8}>
                            {/* <div className="field-label">Reps</div> */}
                            <div>{set.reps}</div>
                          </Col>
                          <Col span={8}>
                            {/* <div className="field-label">Rest Time</div> */}
                            <div>{set.restTime}s</div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Space>
                </Row>
              </Row>
            );
          })
        )}

        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <div
            className="section-title"
            style={{
              margin: "0rem 0",
            }}
          >
            Feedback:
          </div>

          <Row justify="start" align="middle">
            <Typography.Text
              style={{
                paddingRight: "12px",
              }}
            >
              Rate workout:
            </Typography.Text>
            <Radio.Group
              onChange={(e) => {
                setState({
                  ...state,
                  feedback: {
                    ...state.feedback,
                    emoji: e.target.value,
                  },
                });
              }}
              value={state.feedback?.emoji}
            >
              <Radio.Button
                value={1}
                style={{
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                🙁
              </Radio.Button>
              <Radio.Button
                value={2}
                style={{
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                🙂
              </Radio.Button>
              <Radio.Button
                value={3}
                style={{
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                😍
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row>
            <Input.TextArea
              value={state.feedback?.comment}
              onChange={(e) => {
                setState({
                  ...state,
                  feedback: {
                    ...state.feedback,
                    comment: e.target.value,
                  },
                });
              }}
              placeholder="Any feedback?"
              style={{
                width: "100%",
              }}
              rows={3}
            />
          </Row>

          <Typography.Text type="secondary">
            * Please add feedback to regenerate or save to profile
          </Typography.Text>
          <Row
            gutter={16}
            justify="space-between"
            style={{
              marginTop: "1rem",
            }}
          >
            <Col span={12}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                }}
                icon={<RedoOutlined />}
                onClick={() => {
                  // regenerate();
                  templateAction("regenerate");
                }}
                disabled={
                  state.feedback?.emoji && state.feedback?.comment
                    ? false
                    : true
                }
              >
                Regenerate
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{
                  width: "100%",
                }}
                icon={<SaveOutlined />}
                onClick={() => {
                  // setState({
                  //   ...state,
                  //   showSaveModal: true,
                  // });
                  templateAction("save");
                }}
                disabled={
                  state.feedback?.emoji && state.feedback?.comment
                    ? false
                    : true
                }
              >
                Save to Profile
              </Button>
            </Col>
          </Row>
        </Space>
      </Space>
    </div>
  );
};
const LoadingScreen = () => {
  const [currentMessage, setCurrentMessage] = React.useState("Just a sec...");

  let messages = [
    "Just a sec...",
    "Almost there...",
    "Loading...",
    "Please wait...",
    "Fetching data...",
  ];

  React.useEffect(() => {
    let interval = setInterval(() => {
      let randomIndex = Math.floor(Math.random() * messages.length);
      setCurrentMessage(messages[randomIndex]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
    // style={{
    //   width: "100%",
    //   height: "100%",
    //   display: "flex",
    // }}
    >
      <Lottie
        animationData={loadingAnimation}
        style={{
          width: "50%",
          margin: "auto",
        }}
      />
      {currentMessage}
    </div>
  );
};

const AIWorkoutTemplates = () => {
  const auth = useAuth();

  const [state, setState] = React.useState({
    config: {} as any | null,
    isLoading: false,
    data: {} as any | null,
    showGeneratedTemplate: false,
    criteria: {} as any,
  });

  const generateWorkout = async (values: any) => {
    if (values == undefined && state.criteria == undefined) {
      return;
    }
    if (values == undefined) {
      values = state.criteria;
    }
    setState({ ...state, isLoading: true });

    let userDetails = {
      fitnessGoal: values.goal,
      gymExperience: values.fitnessLevel,
      workoutFreq: values.workoutFrequency,
      equipments: values.equipment,
    };

    let workoutGenCriteria = {
      includeWarmupSet: values.inlcudeWarmupSet,
      preferredNumOfSets: 4,
      creativity: values.creativityLevel,
      totalWorkoutTime: values.duration,
      intensity: values.intensity,
      readiness: "exceptional",
      muscleGroups: values.targetMuscleGroup,
      includeSuperset: values.includeSuperset,
    };

    let url = `${API_BASE_URL}arnold/suggest-template`;
    let data = {
      userDetails,
      workoutCriteria: workoutGenCriteria,
    };

    try {
      let idToken = await auth.user?.getIdToken();
      let headers = {
        Authorization: `Bearer ${idToken}`,
      };
      let res = await axios.post(url, data, { headers });
      console.log(res.data);
      setState({
        ...state,
        isLoading: false,
        data: res.data,
        showGeneratedTemplate: true,
        criteria: data,
      });
    } catch (e) {}
  };

  return (
    <Card className="content">
      {/* <div className="workout-templates"> */}
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          minHeight: "100%",
        }}
      >
        {state.isLoading ? (
          <>
            <LoadingScreen />
          </>
        ) : null}
        {!state.isLoading ? (
          state.showGeneratedTemplate ? (
            // <TemplateView data={state.data} />
            <TemplateView
              templateId={state.data.templateID}
              // templateId="65cc6344c1c8e445dcfe0a6a"
              userId={auth.user?.uid || ""}
              criteria={state.criteria}
              regenerate={() => {
                generateWorkout(state);
              }}
            />
          ) : (
            <AIWorkoutConfig
              onGenerate={(state) => {
                generateWorkout(state);
              }}
            />
          )
        ) : null}
      </Row>
      {/* </div> */}
    </Card>
  );
};

export default AIWorkoutTemplates;
